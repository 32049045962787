import Product from './product/Product'
import React, {useState, useContext, useEffect} from 'react'
import {TcmsContext} from '../../contexts/TcmsContext'
import {useHistory} from "react-router-dom";
import slug from "../../services/slug";
import TcmsSpan from '../../tcms/Tags/TcmsSpan';
import TcmsH1 from '../../tcms/Tags/TcmsH1';

function Favorites({ replaceWithPdp }) {
  const context = useContext(TcmsContext)
  const history = useHistory()
  const [products, setProducts] = useState([])

  useEffect(() => {
    const getFavorites = () => {
      let products = context.products.products.filter(el => context.favorites.indexOf(el.id) !== -1)
      setProducts(products)
    }
    getFavorites()
  }, [context.products.products, context.favorites])

  /**
   * This is Fuzzy code..
   */
  const toPdp = (product) => {
    if (replaceWithPdp) {
      replaceWithPdp(product.id)
    } else {
      history.push(`/${context.webshopSlug}/product/${product.id}/${slug(product.display_name)}`)
    }
  }

  if (!products) return null

  return <div className="p-grid">
    <div className="p-col-12">
      <TcmsH1>favorieten_titel</TcmsH1>
    </div>
    {products.length === 0 &&
      <div className='p-col-12'>
        <TcmsSpan>no_favorites</TcmsSpan>
      </div>
    }
    {products.map((product, i) =>
      <Product key={i}
        originalIndex={product.originalIndex}
        id={product.id}
        display_name={product.display_name}
        cat_name={product.cat_name}
        category_id={product.category_id}
        price_in_eco={product.price_in_eco}
        stock={product.stock}
        image_name={product.image_name}
        ribbon_ids={product.ribbon_ids}
        ambiance_images={product.ambiance_images}
        productClicked={() => toPdp(product)} />
    )}
  </div>

}

export default Favorites
