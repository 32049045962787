import React, { useContext, useRef } from "react";
import { TcmsContext } from "../../../contexts/TcmsContext";
import TcmsFloatingLabel from "../../../tcms/Tags/TcmsFloatingLabel";
import Menu from "../../../components/webshop/menu/Menu";
import Tcms from "../../../tcms/Tcms";
import TcmsLabel from "../../../tcms/Tags/TcmsLabel";
import TcmsDropdown from "../../../tcms/Tags/TcmsDropdown";
import TcmsP from "../../../tcms/Tags/TcmsP";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import DialogOutOfStock from '../../../components/webshop/DialogOutOfStock'
import { addLocale } from 'primereact/api';
import { removeFromCart } from "../../../services/cart";
import { Checkbox } from "primereact/checkbox";
import Background from "../../../components/webshop/background/Background";
import { useState } from "react";
import TcmsSpan from "../../../tcms/Tags/TcmsSpan";
import Footer from '../../../components/webshop/footer/Footer';
import { useEffect } from "react";
import DialogPickupPoint from "../../../components/webshop/DialogPickupPoint";

export default function PersonalData(props) {

  const [blockOrder, setBlockOrder] = useState(false);
  const [notSameEmails, setNotSameEmails] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [showNames, setShowNames] = useState(true);

  const refDialogPickupPoint = useRef();


  const checkEmail = (e) => {
    let email = document.getElementById('private_email').value;

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(email)) {

      setInvalidEmail(false);

      let repeatEmail = e.target.value;
      if (email === repeatEmail) {
        setNotSameEmails(false);
        setBlockOrder(false);
      } else {
        setNotSameEmails(true);
        setBlockOrder(true);
      }
    } else {
      setBlockOrder(true);
      setInvalidEmail(true);
    }

  }

  const context = useContext(TcmsContext)
  const { rootComponent } = props
  const { state } = rootComponent

  const checkIfNameRequired = (e) => {
    if (!showNames) {
      state.data.firstname = '***';
      state.data.lastname = '***';
    }
  }

  const showPickupDialog = (val) => {
    if (val) {
      refDialogPickupPoint.current.open();
    }
  }

  addLocale('nl', {
    firstDayOfWeek: 1,
    dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
    dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
    dayNamesMin: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
    monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
    today: 'Vandaag',
    clear: 'Wis'
  });

  if (context.meta.deliverymethods === 'central_on_name' && !context.shopModus && context.deliveryAddresses.length === 2) {
    state.data.delivery_address_id = context.deliveryAddresses[1].id
  }

  useEffect(() => {
    if (context.meta.wso_id === 953 || context.meta.wso_id === 1091) {
      if (!context.cart['letter-or-parcel']) {
        setShowNames(false);
      }
    }
  }, [context.meta.wso_id, context.cart]);

  /**
   *
   * @returns {JSX.Element}
   */
  const nameAndEmailFields = () => {
    return <div className="p-fluid">
      {showNames &&
        <>
          <div className="p-field">
            <TcmsFloatingLabel name="firstname"
              data={state.data}
              errors={state.laravelErrors}
              dataChanged={rootComponent.dataChanged}
              placeholder="Voornaam*" />
          </div>
          <div className="p-field">
            <TcmsFloatingLabel name="lastname"
              autoComplete="off"
              data={state.data}
              errors={state.laravelErrors}
              dataChanged={rootComponent.dataChanged}
              placeholder="Achternaam*" />
          </div>
        </>
      }
      {state.blockEmail ?
          <div className="p-field">
            <div class="p-float-label">
              {state.data.private_email}
              <label>Email</label>
            </div>
          </div>
        :
        <>
          <div className="p-field">
            <TcmsFloatingLabel name="private_email"
              autoComplete="off"
              data={state.data}
              errors={state.laravelErrors}
              onBlur={(e) => {
                checkIfNameRequired(e)
              }}
              dataChanged={rootComponent.dataChanged}
              placeholder="E-mailadres*" />
            {invalidEmail ?
              <small className="p-error">Dit is geen geldig e-mailadres</small> :
              <small className="p-error"></small>
            }
          </div>
          <div className="p-field">
            <div className="p-float-label">
              <label className="pointer_events" htmlFor="repeat_email"><Tcms>Herhaal E-mailadres*</Tcms></label>
              <input id="repeat_email" type="email"
                autoComplete="off"
                placeholder={Tcms('Herhaal E-mailadres*')}
                onBlur={(e) => {
                  checkEmail(e)
                }}
                className="p-inputtext p-component p-filled" />
              {notSameEmails ?
                <small className="p-error">E-mailadressen komen niet overeen</small> :
                <small className="p-error"></small>
              }

            </div>
          </div>
        </>
      }
      
    </div>
  }

  /**
   *
   * @returns {JSX.Element}
   */
  const centralOnNameField = () => {
    return <div className="p-fluid">
      <div className="p-field">
        {context.cart['letter-or-parcel'] && context.cart['parcel'] && context.deliveryAddresses.length > 2 &&
          <TcmsDropdown value={state.data.delivery_address_id}
            optionLabel="name"
            optionValue="id"
            options={context.deliveryAddresses}
            onChange={(e) => rootComponent.setDeliveryAddressId(e.value)} />
        }
        {context.cart['letter-or-parcel'] && context.cart['parcel'] && context.deliveryAddresses.length === 2 &&
          <div>
            <label>Afleverlocatie</label>
            <p>{context.deliveryAddresses[1].name}</p>
          </div>
        }
        {/*Translate them errors too*/}
        {state.laravelErrors && state.laravelErrors['delivery_address_id']
          ? <small className="p-error"><Tcms>{state.laravelErrors['delivery_address_id']}</Tcms></small>
          : <small className="p-error">&nbsp;</small>
        }
      </div>
    </div>
  }

  /**
   *
   * @returns {JSX.Element}
   */
  const addressFields = () => {
    return <>
      <div className="p-formgroup-inline p-justify-between">
        <div className="p-field p-lg-5 p-col-12">
          <TcmsFloatingLabel name="zip"
            data={state.data}
            errors={state.laravelErrors}
            dataChanged={rootComponent.dataChanged}
            onBlur={rootComponent.doZipCheck}
            placeholder="Postcode*" />

          {/*Translate them errors too*/}
          {state.zipCheckError
            ? <small className="p-error"><Tcms>{state.zipCheckError}</Tcms></small>
            : <small className="p-error">&nbsp;</small>
          }



        </div>
        <div className="p-field p-lg-4 p-col-6">
          <TcmsFloatingLabel name="huisnummer"
            data={state.data}
            errors={state.laravelErrors}
            dataChanged={rootComponent.dataChanged}
            onBlur={rootComponent.doZipCheck}
            placeholder="Huisnummer*" />
        </div>
        <div className="p-field p-lg-3 p-col-6">
          <TcmsFloatingLabel name="toevoeging"
            data={state.data}
            errors={state.laravelErrors}
            dataChanged={rootComponent.dataChanged}
            placeholder="Huisnr. ext." />
        </div>
        <div className="p-field p-lg-6 p-col-12">
          <TcmsFloatingLabel name="straatnaam"
            data={state.data}
            errors={state.laravelErrors}
            dataChanged={rootComponent.dataChanged}
            placeholder="Straatnaam*" />
        </div>
        <div className="p-field p-lg-6 p-col-12">
          <TcmsFloatingLabel name="city"
            data={state.data}
            errors={state.laravelErrors}
            dataChanged={rootComponent.dataChanged}
            placeholder="Plaatsnaam*" />
        </div>
      </div>
      <div className="p-fluid">
        <div className="p-field">
          <div className="p-float-label"><label>
            {/* Dirty hack to fix autocomplete fields. !!! LEAVE THIS ONE IN unless you really know what you are doing !!! */}
            <span>L</span>
            <span style={{ fontSize: '1px' }}>&nbsp;</span>
            and *
          </label>
          </div>
          <TcmsDropdown value={state.data.country_id}
            placeHolder="Land"
            optionLabel="name"
            optionValue="id"
            options={context.shipTo}
            onChange={(e) => rootComponent.setCountry(e.value)} />
          {/*Translate them errors too*/}
          {state.laravelErrors && state.laravelErrors['country_id']
            ? <small className="p-error"><Tcms>{state.laravelErrors['country_id']}</Tcms></small>
            : <small className="p-error">&nbsp;</small>
          }
        </div>
      </div>
    </>
  }

  /**
   *
   * @returns {JSX.Element}
   */
  const pickupFields = () => {
    return (
      <>
        {/* When we're allowed to use pickup point (letter only for instance is a no go here) */}
        <div className="question">
          {context.editMode &&
            <div className='tcms-edit-wrapper'>
              <div className='home_toggle'>
                <div className="label">Afhaalpunt tooltip</div>
                <div className="value"><Tcms>afhaalpunt_tooltip</Tcms></div>
              </div>
            </div>
          }
          <span className="relative"><Tcms>Wil je jouw pakket laten bezorgen op een afhaalpunt?</Tcms>
            {/* <div className='tooltip-icon pi pi-info-circle'></div>
            <div className='tooltip'>
              <Tcms>afhaalpunt_tooltip</Tcms>
            </div> */}
          </span>
        </div>
        <div className="p-formgroup-inline">
          <div className="p-field-radiobutton">
            <RadioButton inputId="pickup1"
              name="pickup"
              value={1}
              onChange={(e) => {
                showPickupDialog(e.value)
                rootComponent.setState({ pickup: e.value });
                rootComponent.setState({ carrier_slug: 'postnl' });
              }}
              checked={state.pickup === 1} />
            <TcmsLabel htmlFor="pickup1">Ja</TcmsLabel>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton inputId="pickup2"
              name="pickup"
              value={0}
              onChange={(e) => {
                rootComponent.setState({ pickup: e.value });
                rootComponent.setState({ carrier_slug: '' });
              }}
              checked={state.pickup === 0} />
            <TcmsLabel htmlFor="pickup2">Nee</TcmsLabel>
          </div>
        </div>

        {state.pickup === 1 &&
          <React.Fragment>
            {/* When we're allowed to use pickup point (letter only for instance is a no go here) */}
            <div className="carriers">
              <div className="question">
                <Tcms>Kies je transporteur</Tcms>
              </div>
              <div className="p-formgroup">
                <div className="p-field-radiobutton">
                  <img className="carrier-icon" alt="PostNL" src="/media/images/default/postnl.png" />
                  <RadioButton inputId="carrier_slug_postnl"
                    name="pickup"
                    value="postnl"
                    onChange={(e) => rootComponent.setState({ carrier_slug: e.value })}
                    checked={state.carrier_slug === 'postnl'} />
                  <TcmsLabel htmlFor="carrier_slug_postnl"> PostNL</TcmsLabel>
                </div>
                {/* Homerr disabled for now. When enabling also remove the setState({carrier_slug}, '<value>>') from the pickup radiobuttons /}
              {/*<div className="p-field-radiobutton homer-radio">*/}
                {/*  <img className="carrier-icon" alt="PostNL" src="/media/images/default/homerr.png"/>*/}
                {/*  <RadioButton inputId="carrier_slug_homerr"*/}
                {/*               name="pickup"*/}
                {/*               value="homerr"*/}
                {/*               onChange={(e) => rootComponent.setState({carrier_slug: e.value})}*/}
                {/*               checked={state.carrier_slug === 'homerr'}/>*/}
                {/*  <TcmsLabel htmlFor="carrier_slug_homerr">Homerr</TcmsLabel>*/}
                {/*  <i className="pi pi-info icon-homer-info"></i>*/}
                {/*  <div className="homer-help">*/}
                {/*    <Tcms>Laat je pakket kosteloos en duurzaam verzenden met Homerr en ontvang een extra cadeautje in je pakket. Haal je pakket vervolgens op bij één van de 1.600 HomerrPunten en bespaar tot wel 79% CO2 uitstoot per pakket.*/}
                {/*      Het pakket is iets langer onderweg (3-5 werkdagen), maar wordt een stuk duurzamer verzonden! Volg je pakket via een Track & Trace code en klik hier om een Homerrpunt bij jou in de buurt te vinden.</Tcms>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>

            <div className="manual">
              <Tcms>Vul dan je postcode in en klik daarna op 'Zoek servicepunt'.</Tcms>
            </div>
            <div className="carrier">
              <div className="p-field  p-lg-6 p-col-12">
                <TcmsFloatingLabel name="serach_zip"
                  data={state}
                  dataChanged={(e) => rootComponent.setState({ serach_zip: e.target.value })}
                  placeholder="Postcode*" />

                {/*Translate them errors too*/}
                {state.laravelErrors && state.laravelErrors['sp_id']
                  ? <small className="p-error"><Tcms>{state.laravelErrors['sp_id']}</Tcms></small>
                  : <small className="p-error">&nbsp;</small>
                }
              </div>
              <div className="p-field  p-lg-6 p-col-12">
                <Tcms>
                  <Button label="Zoek servicepunt" onClick={() => rootComponent.searchDeliveryPoint()} />
                </Tcms>
              </div>

            </div>

            <div className="carrier-data">
              <div className="p-field p-col-12">
                <TcmsFloatingLabel name="sp_name"
                  disabled={true}
                  data={state.carrier}
                  placeholder="Afhaalpunt" />
              </div>
              <div className="p-field p-col-12">
                <TcmsFloatingLabel name="sp_address"
                  disabled={true}
                  data={state.carrier}
                  placeholder="Straat" />
              </div>
              <div className="p-field p-col-12">
                <TcmsFloatingLabel name="sp_zip"
                  disabled={true}
                  data={state.carrier}
                  placeholder="Postcode" />
              </div>
              <div className="p-field p-col-12">
                <TcmsFloatingLabel name="sp_house_number"
                  disabled={true}
                  data={state.carrier}
                  placeholder="Huisnummer" />
              </div>

              <div className="p-field p-col-12">
                <TcmsFloatingLabel name="sp_city"
                  disabled={true}
                  data={state.carrier}
                  placeholder="Plaats" />
              </div>
              <div className="p-field p-col-12">
                <TcmsFloatingLabel name="sp_country"
                  disabled={true}
                  data={state.carrier}
                  placeholder="Land" />
              </div>
            </div>
          </React.Fragment>
        }

        {state.pickup === 0 && context.meta.cycloon === 1 &&
          <React.Fragment>
            {/* Delivery options */}
            <div className="carriers">
              <div className="question">
                <Tcms>Kies je transporteur</Tcms>
              </div>
              <div className="p-formgroup">
                <div className="p-field-radiobutton">
                  <img className="carrier-icon" alt="PostNL" src="/media/images/default/postnl.png" />
                  <RadioButton inputId="carrier_name_postnl"
                    name="carrier"
                    value="postnl"
                    onChange={(e) => rootComponent.setState({ carrier_name: e.value })}
                    checked={state.carrier_name === 'postnl'} />
                  <TcmsLabel htmlFor="carrier_name_postnl">PostNL</TcmsLabel>
                </div>
                {state.cycloonCheck === 1 &&
                  <div className="p-field-radiobutton">
                    <img className="carrier-icon" alt="Cycloon" src="/media/icons/logo-cycloon.svg" />
                    <RadioButton inputId="carrier_name_cycloon"
                      name="carrier"
                      value="cycloon"
                      onChange={(e) => rootComponent.setState({ carrier_name: e.value })}
                      checked={state.carrier_name === 'cycloon'} />
                    <TcmsLabel htmlFor="carrier_name_cycloon">Cycloon</TcmsLabel>
                  </div>
                }
              </div>
            </div>
          </React.Fragment>
        }
      </>
    )
  }
  /**
   *ALGEMENE VOORWAARDEN
   */
  const algemeneVoorwaarden = () => {

    return <div className="p-fluid">
      <div className="p-field">
        {context.webshopId === 953 &&
          <div className="p-col">
            <Checkbox
              onChange={e => rootComponent.dataChanged(e, 'medewerker_enquete')}
              id="medewerker_enquete"
              inputId="lab_medewerker_enquete"
              value={1}
              checked={state.data.medewerker_enquete === 1}
            />
            <label htmlFor="lab_medewerker_enquete" style={{ fontWeight: 'bold', color: '#666666', fontSize: '16px' }}> <TcmsSpan>label_medewerkers_enquete</TcmsSpan></label>
          </div>
        }
        <div className="p-col">
          {/* <Checkbox
            onChange={e => rootComponent.dataChanged(e, 'algemene_voorwaarden')}
            id="algemene_voorwaarden"
            inputId="lab_alg_v"
            checked={state.data.algemene_voorwaarden === 1} /> */}
          {/* <label htmlFor="lab_alg_v" style={{ fontWeight: 'bold', color: '#666666', fontSize: '16px' }}> <TcmsSpan>label_privacy_policy</TcmsSpan></label> */}
          <a
            //  href="https://www.morethangifts.nl/wp-content/uploads/2021/11/Algemene-Voorwaarden-en-Privacy-Verklaring-zoals-bij-KvK-v11.pdf"
            href={'/' + context.webshopSlug + '/' + Tcms('privacy_link')}
            rel="noreferrer"
            style={{ color: '#666666', textDecoration: 'none', fontSize: '14px' }}
          >
            <Tcms>privacy_link_label</Tcms>
          </a>
          {context.editMode &&
            <div className="tcms-edit-wrapper">
              <div className="privacy_label">
                <div className="label">
                  Privacy link:
                </div>
                <div className="value">
                  <Tcms>privacy_link</Tcms>
                </div>
              </div>
              <div className="privacy_label">
                <div className="label">
                  Privacy link label:
                </div>
                <div className="value">
                  <Tcms>privacy_link_label</Tcms>
                </div>
              </div>
            </div>
          }
        </div>
        {/*Translate them errors too*/}
        {state.laravelErrors && state.laravelErrors['algemene_voorwaarden']
          ? <small className="p-error" style={{ left: 0 }}><Tcms>{state.laravelErrors['algemene_voorwaarden']}</Tcms></small>
          : <small className="p-error">&nbsp;</small>
        }

      </div>
      {/* <div className="p-col">
        <Checkbox
          id="terms"
          inputId="terms_v"
        />
        <label htmlFor="terms_v" style={{ fontWeight: 'bold', color: '#666666', fontSize: '16px' }}> <TcmsSpan>terms_conditions</TcmsSpan></label>
        &nbsp;
        <a target="_blank"
          href="https://morethangifts.nl/Algemene-Voorwaarden-More-Than-Gifts.pdf"
          style={{ color: '#666666', textDecoration: 'none', fontSize: '14px' }}>
          <Tcms>terms_link</Tcms>
        </a>
      </div> */}


    </div>

  }

  const optionHarmonica = () => {
    const addDays = (date, days) => {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }
    const getFirstDeliveryDate = () => {
      const servertime = new Date(context.meta.servertime)

      let add
      switch (servertime.getDay()) {
        case 0: // zo -> wo
        case 1: // ma -> do
        case 2: // di -> vr
        case 3: // wo -> za
          add = 3
          break
        case 4: // do -> next week di
        case 5: // vr -> next week wo
          add = 5
          break;
        case 6:
          add = 4; // za -> wo next week
          break;
        default:
          add = 5; // Just in case (you should not end up here)
      }
      let fin = addDays(servertime, add)
      // @todo sat 1 jan and sat 25 dec
      return fin

    }


    const { meta } = context
    return (meta.local_cart_gif_away || meta.local_cart_delivery_time)
      ? <>
        <Accordion multiple={true}>
          {meta.local_cart_delivery_time &&
            <AccordionTab header={<Tcms>Later je pakket ontvangen?</Tcms>}>
              <TcmsP>package_intro</TcmsP>
              <div className="p-formgroup-inline">
                <div className="p-field-radiobutton p-col-12 p-lg-5 p-xl-3">
                  <RadioButton inputId="later_ontvangen_1"
                    name="local_cart_delivery_time"
                    value={0}
                    onChange={(e) => {
                      rootComponent.setState({ laters: 0 })
                    }}
                    checked={rootComponent.state.laters === 0} />
                  <TcmsLabel htmlFor="later_ontvangen_1">Zo snel mogelijk</TcmsLabel>
                </div>
                <div className="p-field-radiobutton p-col-12 p-lg-5 p-xl-3">
                  <RadioButton inputId="later_ontvangen_2"
                    name="local_cart_delivery_time"
                    value={1}
                    onChange={(e) => {
                      rootComponent.setState({
                        laters: 1,
                        data: { ...state.data, local_cart_delivery_time: state.local_cart_delivery_time || getFirstDeliveryDate() }
                      })
                    }}
                    checked={rootComponent.state.laters === 1} />
                  <TcmsLabel htmlFor="later_ontvangen_2">Een later tijdstip</TcmsLabel>
                </div>
                {rootComponent.state.laters === 1 &&
                  <div className="p-field p-col-12 p-xl-4 p-lg-12 ">
                    <Calendar
                      dateFormat="d M yy"
                      locale="nl"
                      className="mtg-ps-calendar"
                      minDate={getFirstDeliveryDate()}
                      disabledDays={[0, 1]} // zo ma
                      disabledDates={[
                        new Date('01 Jan 2022 00:00:00 GMT'),
                        new Date('25 Dec 2021 00:00:00 GMT'),
                      ]}
                      value={state.data.local_cart_delivery_time}
                      readOnlyInput
                      onChange={(e) => {
                        rootComponent.setState({ data: { ...rootComponent.state.data, local_cart_delivery_time: e.value } })
                      }} showIcon />
                  </div>}
              </div>

            </AccordionTab>}
          {meta.local_cart_gif_away &&
            <AccordionTab header={<Tcms>Je geschenk cadeau geven?</Tcms>}>
              <TcmsP>giveaway_intro</TcmsP>

              <div className="p-formgroup-inline">
                <div className="p-field-radiobutton p-col-12">
                  <Tcms>Geef het geschenk cadeau</Tcms>
                </div>
                <div className="p-field-radiobutton p-col-12 p-sm-2">
                  <RadioButton inputId="giveaway_2"
                    value={0}
                    onChange={(e) => {
                      rootComponent.setState({ giveaway: 0 })
                      removeFromCart(context, 65607)
                    }}
                    checked={rootComponent.state.giveaway === 0} />
                  <TcmsLabel htmlFor="giveaway_2">Nee</TcmsLabel>
                </div>
                <div className="p-field-radiobutton p-col-12 p-sm-2">
                  <RadioButton inputId="giveaway_1"
                    value={1}
                    onChange={(e) => {
                      rootComponent.setState({ giveaway: 1 })
                    }}
                    checked={rootComponent.state.giveaway === 1} />
                  <TcmsLabel htmlFor="giveaway_1">Ja</TcmsLabel>
                </div>

                {rootComponent.state.giveaway === 1 &&
                  <>
                    <div className="p-field p-col-12 p-xl-12 p-lg-12">
                      <TcmsFloatingLabel name="gift_from"
                        className="giveaway"
                        data={state.data}
                        errors={state.laravelErrors}
                        dataChanged={rootComponent.dataChanged}
                        placeholder="Jouw naam*"
                        onBlur={(e) => { sessionStorage.setItem("gift_from", e.target.value); }} />
                    </div>
                    <div className="p-field p-col-12 p-xl-12 p-lg-12">
                      <TcmsFloatingLabel name="gift_from_text"
                        className="giveaway"
                        data={state.data}
                        errors={state.laravelErrors}
                        dataChanged={rootComponent.dataChanged}
                        placeholder="Bericht"
                        maxlength="200"
                        onBlur={(e) => { sessionStorage.setItem("gift_from_text", e.target.value); }} />
                      <p style={{ 'marginTop': 0 }}>Max. 200 karakters</p>
                    </div>
                  </>
                }
              </div>

            </AccordionTab>}
        </Accordion>
      </>
      : null
  }

  return (

    <React.Fragment>

      <div className='page-wrapper'>
        <Background />

        <DialogOutOfStock ref={rootComponent.refDialogOutOfStock} />

        <Menu />

        <div className="p-grid personal-data-content default main-view">
          <div className="p-col-12 p-sm-12 naw">
            <h1><Tcms>Bezorging</Tcms></h1>
          </div>

          <div className="p-lg-6 p-sm-12 naw">
            {/*Always need these*/}
            {nameAndEmailFields()}
            {/*Means we need a pickup point*/}
            {
              context.meta.deliverymethods === 'central_on_name' &&
              !context.shopModus &&
              centralOnNameField()
            }
            {/* We need an address cuz we send either a letter or a parcel */}
            {
              context.cart['letter-or-parcel'] &&
              !context.shopModus &&
              context.meta.deliverymethods !== 'central_on_name' && <>
                {addressFields()}
              </>
            }
            {algemeneVoorwaarden()}

            {context.cart['letter-or-parcel'] && context.cart['parcel'] && context.cart['is-main-warehouse']
              && optionHarmonica()
            }

          </div>

          <div className="p-lg-6 p-sm-12 pickup">
            {/*When alowed to set a pickup point*/}
            {
              context.cart['parcel'] &&
              !context.shopModus &&
              context.meta.deliverymethods === 'address' &&
              pickupFields()
            }
            <Tcms>verzending_vertraging</Tcms>
          </div>
          {context.editMode &&
            <Tcms>Bestel</Tcms>
          }
             <div className="p-field p-col-12 p-col-md-3">
             {rootComponent.state.orderError && (
                    <div style={{ color: 'red', textAlign: 'center', marginBottom: '10px' }}>
                      {rootComponent.state.orderError}
                    </div>
                  )} 
              <Button
                label={Tcms('Bestel')}
                title={blockOrder ? "E-mailadressen komen niet overeen" : ""}
                disabled={blockOrder}
                onClick={() => rootComponent.placeOrder()}
              />
            </div>
        </div>
      </div>
      <DialogPickupPoint className="dialog-pickup-point" ref={refDialogPickupPoint}/>
      <Footer />
    </React.Fragment>
  )
  
}




