import React, { useEffect, useState } from "react";
import Player from "@vimeo/player";
import "./WebshopIntroVideo.scss";
import { getGameScoresByUser } from "../services/gameProgressService";

const WebshopIntroVideo = ({ wsoId, userId }) => {
  const videoUrl = "https://player.vimeo.com/video/1034006980";
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  useEffect(() => {
    const fetchGameProgress = async () => {
      if (![945, 1103].includes(wsoId) || !userId) return;
      try {
        const progress = await getGameScoresByUser(userId);
        if (!progress.result || progress.result.length === 0) {
          // User has not played any games
          setIsDialogVisible(true);
        } else {
          // User has played some games
          const playedGames = progress.result.map((game) => game.game_id);
          const totalGames = 6;
          const nextGame = Array.from(
            { length: totalGames },
            (_, i) => i + 1
          ).find((gameId) => !playedGames.includes(gameId));

          if (nextGame) {
            setIsDialogVisible(true);
          } else {
            // All games are played
            setIsDialogVisible(false);
          }
        }
      } catch (error) {
        console.error("Error fetching game progress:", error);
        setIsDialogVisible(false);
      }
    };

    fetchGameProgress();
  }, [wsoId, userId]);

  useEffect(() => {
    if (!isDialogVisible) return;

    const iframe = document.querySelector(".dialog-video iframe");
    if (!iframe) return;

    const player = new Player(iframe);

    return () => {
      player.off("ended");
    };
  }, [isDialogVisible]);

  if (![945, 1103].includes(wsoId) || !isDialogVisible) {
    return null;
  }

  return (
    <div className="custom-video-dialog">
      <div className="dialog-overlay">
        <div className="dialog-content">
          <button
            className="close-button"
            onClick={() => setIsDialogVisible(false)}
            aria-label="Close"
          >
            &times;
          </button>
          <div className="dialog-video">
            <iframe
              src={`${videoUrl}?autoplay=1`}
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen; encrypted-media"
              allowFullScreen
              title="Introductory Video"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );

};

export default WebshopIntroVideo;
