import React, { useState, useEffect, useContext } from "react";
import GameIntro from '../game-intro/GameIntro';
import GameResult from '../game-result/GameResult';
import "./KeywordQuiz.scss";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import { useHistory } from 'react-router-dom';
import { storeGameData, getGameScoresByUser, updateGameData } from "../services/gameProgressService";
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import gamesTransitionVideo from '../assets/games_transition.mp4';
import pingSound from '../../../../sounds/ping.mp3';
import buzzSound from '../../../../sounds/softbuzz.mp3';
import axios from "axios";

const KeywordQuiz = ({ startGame, setStartGame, fetchScore }) => {
  const [userId, setUserId] = useState('');
  const { setJwtForGames } = useContext(TcmsContext);
  const [shopId, setShopId] = useState('');
  const [existingGameId, setExistingGameId] = useState(null);
  const gameId = 5;

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      axios.defaults.headers.common['MtgJwt'] = storedJwtToken;
      const tokenData = jwtDecode(storedJwtToken);
      setUserId(tokenData?.usr?.uid);
      setShopId(tokenData?.usr?.wid);
      setJwtForGames(storedJwtToken);
    }
  }, [setJwtForGames]);

  const gameIntroText = {
    title: 'Wat weet je van?',
    description: `Na een overheerlijke zonnige wandeling door de natuur ben je aangekomen op tussenstop 5! Bij deze tussenstop staat de Xtormsolarcharger powerbank centraal, deze kunt u na afloop kiezen in de webshop. Bij deze tussenstop moet u zoveel mogelijk kernwoorden raden bij het gegeven onderwerp. U kunt binnen 45 seconden zoveel mogelijk antwoorden geven als u wilt. Raad erop los, succes!🌿`,
    instruction: `Scoor voldoende punten om een speciaal item voor je rugzak te verdienen: de Xtorm 20W Fuel Series Solar Charger 10.000mAh!`,
    showMedals: false
  };

  const [questions, setQuestions] = useState([
    {
      question: "Tesla is hét symbool van innovatie, door de auto-industrie te transformeren met elektrische voertuigen, zelfrijdende technologie en duurzame energieoplossingen.Welke woorden worden vaak genoemd bij het woord Tesla?",
      correctAnswers: [
        { main: "Elon Musk", synonyms: ["Musk", "Elon", "Tesla CEO", "Oprichter Tesla", "CEO Tesla"] },
        { main: "Amerika", synonyms: ["Verenigde Staten", "VS", "USA", "United States", "Amerikaans"] },
        { main: "Elektrische auto", synonyms: ["EV rijden", "Elektrisch rijden", "Duurzaam rijden", "Milieuvriendelijk rijden", "Zonder benzine rijden", "Oplaadbaar rijden", "Zero-emissie", "Groen rijden", "Accurijden", "Rijden op stroom", "Stille auto", "EV", "Elektrisch", "Volledig elektrisch", "Auto", "Accu", "Batterij"] },
        { main: "Autopilot", synonyms: ["Auto-pilot", "Autopiloot", "Zelfrijdende modus", "Zelfsturing", "Zelfrijdende functie", "Zelfrijdende technologie", "Automatische piloot", "Autonoom rijden", "Zelfsturende auto", "Driver assist", "Automatische besturing", "Assistentiesysteem", "Zelfrijdend", "Assistent", "Assist"] },
        { main: "Model S", synonyms: ["Tesla Model S", "Model-S", "S-model", "Tesla S", "S-type", "Tesla sedan", "Tesla luxe model", "Premium Tesla", "Elektrische sedan", "Sportieve Tesla", "Tesla S serie", "Tesla vlaggenschip", "Model S Plaid", "Grote Tesla", "Model S Performance", "Performance", "Plaid", "P90d", "P95d", "P100d", "Verschillende varianten", "Meerdere varianten", "Varianten", "Modellen", "Verschillende modellen", "Meerdere modellen"] },
        { main: "Duurzaamheid", synonyms: ["Duurzaam", "Milieuvriendelijkheid", "Groene energie", "Groen", "Milieu", "Ecologisch", "Klimaatvriendelijk", "Groene toekomst", "Verantwoord ondernemen", "Milieubewust", "Milieuverantwoord", "Ecologische voetafdruk", "Toekomstbestendig", "Energiebesparend", "Milieu-impact", "Toekomst", "Impact"] },
        { main: "Opladen", synonyms: ["Laden", "Laadstation", "Oplaadpunt", "Laadpaal", "Batterij opladen", "Accu opladen", "Elektrisch laden", "Snelladen", "Oplaadtijd", "Laadtijd", "Thuis opladen", "Publiek laden", "Laadproces", "Supercharger", "Stroom laden", "Lader"] }
      ],
      modalText: "Nyck de Vries is een rijder in de Formule E, over Elektrische auto's gesproken. Wat weet jij van Tesla?",
      modalImage: "/media/modal/tesla.jpg",
      keyword: "Tesla"
    },
    {
      question: "Nederland staat wereldwijd bekend als een broedplaats voor innovatie, met baanbrekende ontwikkelingen op het gebied van watermanagement, duurzame energie en technologie. Welke woorden worden vaak genoemd bij het woord Nederland?",
      correctAnswers: [
        { main: "Tulpen", synonyms: ["Keukenhof", "Hollandse bloemen", "Bloemenvelden", "Bloemen", "Bloem", "Voorjaarsbloemen", "Bolbloemen", "Bloembollen", "Bollenvelden", "Bollenstreek", "Snijbloemen", "Tulp", "Tulipa (de Latijnse naam)", "Tulpenvelden", "Tulpenseizoen"] },
        { main: "Molens", synonyms: ["Molen", "Windmolen", "Watermolen", "Korenmolen", "Kinderdijk", "Zaanse Schans", "Molenaar", "Molenwiek"] },
        { main: "Dijken", synonyms: ["Dijk", "Zeedijk", "Rivierdijk", "IJsseldijk", "Kadedijk", "Afsluitdijk", "Deltawerken", "Duin", "Duinen"] },
        { main: "Fietsen", synonyms: ["Fiets", "Fietstocht", "Fietsroute", "Fietsers"] },
        { main: "Water", synonyms: ["Zee", "Rivier", "Grachten", "Wateren", "Waters", "Noordzee", "Waddenzee", "Meer"] },
        { main: "Kaas", synonyms: ["Gouda", "Edammer", "Leerdammer", "Kazen", "Kaasjes", "Kaasblok", "Kaasblokken", "Kaasblokjes", "Kaasplankjes", "Kaasplankje"] },
        { main: "Klompen", synonyms: ["Klomp", "Houten klomp", "Houten klompen", "Werkklompen", "Houten schoen"] }
      ],
      modalText: "Nederland staat wereldwijd bekend om innovatie en tradities zoals tulpen en molens. Wat weet je van Nederland?",
      modalImage: "/media/modal/nederland.jpg",
      keyword: "Nederland"
    },
    {
      question: "Innovatie maakt de winter comfortabeler en duurzamer, met slimme verwarmingssystemen, energiezuinige technieken en geavanceerde sneeuw- en ijsmanagementoplossingen. Welke woorden worden vaak genoemd bij het woord winter?",
      correctAnswers: [
        { main: "Sneeuw", synonyms: ["Skiën", "Sneeuwen", "Sneeuwval", "Sneeuwvlok", "Sneeuwstorm", "Sneeuwbui", "Poedersneeuw", "Wit"] },
        { main: "Vorst", synonyms: ["Strenge vorst", "Lichte vorst", "Vriezen", "Gevroren", "Vries", "Onder nul"] },
        { main: "Koud", synonyms: ["Kou", "Kouder", "Koudst"] },
        { main: "Schaatsen", synonyms: ["Schaats", "Schaatsenrijden", "Schaatsbaan", "Schaatstocht", "Elf steden tocht", "Noren", "Klapschaatsen", "Schaatsijzers", "IJzers"] },
        { main: "Feestdagen", synonyms: ["Kerst", "Oud en nieuw", "Sinterklaas", "Oudjaarsdag", "Nieuwjaarsdag", "Eerste kerstdag", "Tweede kerstdag", "Kerstavond", "Pakjesavond"] },
        { main: "IJs", synonyms: ["IJzig", "IJslaag", "IJsblok", "IJspegel", "IJzel", "Natuurijs", "Kunstijs"] },
        { main: "Warme chocolademelk", synonyms: ["Warme choco", "Chocolademelk", "Chocomel", "Hete chocolademelk", "Cacao", "Chocolade"] }
      ],
      modalText: "Innovatie maakt de winter aangenamer. Wat weet jij over termen die vaak met winter worden geassocieerd?",
      modalImage: "/media/modal/winter.jpg",
      keyword: "Winter"
    },
    {
      question: "Innovatie haalt het maximale uit de zon, met baanbrekende technologieën zoals zonnepanelen, zonne-energiecentrales en toepassingen in duurzame energieopwekking. Welke woorden worden vaak genoemd bij het woord zon?",
      correctAnswers: [
        { main: "Warmte", synonyms: ["Hitte", "Warme temperatuur", "Warm gevoel", "Verwarming", "Heet", "Warmtebron", "Opwarming", "Warme lucht"] },
        { main: "Energie", synonyms: ["Zonne-energie", "Windenergie", "Waterkracht", "Zonne energie", "Zon energie", "Kernfusie", "Zonnepaneel", "Zonnepanelen", "Zon paneel"] },
        { main: "Maan", synonyms: ["Volle maan", "Nieuwe maan"] },
        { main: "Strand", synonyms: ["Zandstrand", "Kust", "Zand", "Zee", "Zonnen", "Bakken"] },
        { main: "Zomer", synonyms: ["Zome", "Zomers", "Zomerse"] },
        { main: "Vakantie", synonyms: ["Zomervakantie"] }
      ],
      modalText: "De zon is een bron van leven en energie. Wat weet jij over termen die vaak met de zon worden geassocieerd?",
      modalImage: "/media/modal/zon.jpg",
      keyword: "Zon"
    },
    {
      question: "Innovatie is ook terug te zien bij hobby’s van veel mensen. Denk hierbij aan het gebruik van smartwatches om mee te sporten, een boek lezen op een e-reader, online platformen en apps voor cursussen, 3D-printers of het gebruik van fotografie apps met AI-bewerkingen. Welke woorden worden vaak genoemd bij het woord boek?",
      correctAnswers: [
        { main: "Verhaal", synonyms: ["Verhaallijn", "Anekdote", "Sprookje", "Mythe", "Verhalen"] },
        { main: "Lezen", synonyms: ["Lees", "Gelezen", "Las", "Voorlezen", "Snel lezen", "Stil lezen"] },
        { main: "Auteur", synonyms: ["Schrijver", "Schrijfster", "Novelist", "Writer"] },
        { main: "Literatuur", synonyms: ["Literair", "Literaire werken", "Literatuurstudie"] },
        { main: "Kaft", synonyms: ["Boekenkaft", "Schoolboeken", "Karton", "Harde kaft", "Zachte kaft", "Boekomslag", "Cover"] },
        { main: "Bibliotheek", synonyms: ["Boekenkast", "De Bieb", "Bieb", "Biblio", "Bibliotheek"] },
        { main: "Roman", synonyms: ["Romantiek", "Genre", "Fictie", "Non-fictie", "Fantasyroman", "Thriller"] }
      ],
      modalText: "Boeken zijn niet alleen een bron van kennis, maar ook van ontspanning en avontuur. Wat weet jij over termen die vaak met boeken worden geassocieerd?",
      modalImage: "/media/modal/boek.jpg",
      keyword: "Boek"
    }
  ]);

  const [inputValue, setInputValue] = useState('');
  const [allGuesses, setAllGuesses] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeftPerQuestion, setTimeLeftPerQuestion] = useState();
  const [gameFinished, setGameFinished] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [showCorrectAnswers, setShowCorrectAnswers] = useState(false);
  const [showAllAnswersGiven, setShowAllAnswersGiven] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [isAnswerInputDisabled, setIsAnswerInputDisabled] = useState(false);
  const [canAnswer, setCanAnswer] = useState(false);

  // Fetch existing game progress to check for existing entries
  useEffect(() => {
    const fetchGameProgress = async () => {
      try {
        const progress = await getGameScoresByUser(userId);
        const gameEntry = progress.result.find(
          (entry) => entry.game_id === gameId && entry.webshoporder_id === shopId
        );
        if (gameEntry) {
          setExistingGameId(gameEntry.id);
        }
      } catch (error) {
        console.error('Error fetching game progress:', error.message);
      }
    };
    if (userId) {
      fetchGameProgress();
    }
  }, [userId, shopId]);

  // Handle saving progress if the user tries to leave the page
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
      position: 'bottom-center',
      duration: 5000,
      style: {
        background: '#facc15',
        color: '#000',
      },
    });

    const saveProgress = async () => {
      try {
        const correctGuessesCount = allGuesses
          .flat()
          .filter(guessObj => guessObj && guessObj.isCorrect === true)
          .length;
        if (existingGameId) {
          await updateGameData(existingGameId, correctGuessesCount);
        } else {
          const response = await storeGameData(userId, gameId, shopId, correctGuessesCount);
          setExistingGameId(response.result.id);
        }
      } catch (error) {
        console.error('Error saving progress on exit:', error.message);
      }
    };
    saveProgress();
  };

  useEffect(() => {
    if (gameStarted && !gameFinished) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [gameStarted, gameFinished, existingGameId, userId, gameId, shopId, allGuesses]);

  useEffect(() => {
    if (gameFinished) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [gameFinished]);

  useEffect(() => {
    if (gameFinished) {
      const saveProgress = async () => {
        try {
          const correctGuessesCount = allGuesses.flat().filter(guessObj => guessObj && guessObj.isCorrect === true).length;
          if (existingGameId) {
            // Update the existing entry
            await updateGameData(existingGameId, correctGuessesCount);
          } else {
            // Create a new entry
            const response = await storeGameData(userId, gameId, shopId, correctGuessesCount);
            setExistingGameId(response.result.id);
          }
          if (fetchScore) {
            fetchScore(true);
          }
        } catch (error) {
          console.error('Error saving game progress:', error.message);
        }
      };
      saveProgress();
    }
  }, [gameFinished]);

  const showQuestionImage = (questionIndex) => {
    const question = questions[questionIndex];
    if (question) {
      setCanAnswer(true);
      setInputValue('');
      setModalImage(question.modalImage);
    }
  };

  const beginGame = () => {
    setGameStarted(true);
    showQuestionImage(currentQuestionIndex);
    setTimeLeftPerQuestion(55);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.trim() && !gameFinished) {
      const updatedGuesses = [...allGuesses];
      const currentGuesses = updatedGuesses[currentQuestionIndex] || [];
      const updatedQuestions = [...questions];
      const currentQuestion = updatedQuestions[currentQuestionIndex];

      const SIMILARITY_THRESHOLD = 1;

      const isAlreadyGuessed = currentGuesses.some(guessObj => {
        const allAnswers = [guessObj.correctAnswer, ...(guessObj.correctDisplayAnswer?.synonyms || [])];
        return allAnswers.some(answer =>
          calculateLevenshteinDistance(answer.toLowerCase(), inputValue.toLowerCase()) <= SIMILARITY_THRESHOLD
        );
      });

      if (isAlreadyGuessed) {
        const wasCorrectBefore = currentGuesses.some(guessObj =>
          guessObj.isCorrect &&
          [guessObj.correctAnswer, ...(guessObj.correctDisplayAnswer?.synonyms || [])].includes(inputValue.toLowerCase())
        );

        currentGuesses.unshift({
          guess: inputValue,
          isCorrect: wasCorrectBefore,
          correctAnswer: wasCorrectBefore ? inputValue : '',
          correctDisplayAnswer: wasCorrectBefore
            ? currentGuesses.find(guessObj =>
              [guessObj.correctAnswer, ...(guessObj.correctDisplayAnswer?.synonyms || [])].includes(inputValue.toLowerCase())
            ).correctDisplayAnswer
            : null,
          isRepeated: true
        });

        new Audio(buzzSound).play();

        setAllGuesses(updatedGuesses);
        setInputValue('');
        return;
      }

      const isDuplicate = currentGuesses.some(guessObj =>
        guessObj.guess.toLowerCase() === inputValue.toLowerCase()
      );

      if (isDuplicate) {
        currentGuesses.unshift({
          guess: inputValue,
          isCorrect: false,
          correctAnswer: '',
          correctDisplayAnswer: null,
          isRepeated: true,
        });

        new Audio(buzzSound).play();

        setAllGuesses(updatedGuesses);
        setInputValue('');
        return;
      }

      const matchedAnswer = currentQuestion.correctAnswers.find(answerObj => {
        const allValidAnswers = [answerObj.main, ...answerObj.synonyms].map(ans => ans.toLowerCase());
        return allValidAnswers.includes(inputValue.toLowerCase());
      });

      const matchedByLevenshtein = currentQuestion.correctAnswers.find(answerObj => {
        const allValidAnswers = [answerObj.main, ...answerObj.synonyms].map(ans => ans.toLowerCase());
        return allValidAnswers.some(answer =>
          calculateLevenshteinDistance(answer, inputValue.toLowerCase()) <= SIMILARITY_THRESHOLD
        );
      });

      const isCorrect = matchedAnswer || matchedByLevenshtein;

      if (isCorrect) {
        const correctAnswerObj = matchedAnswer || matchedByLevenshtein;
        currentGuesses.unshift({
          guess: inputValue,
          isCorrect: true,
          correctAnswer: correctAnswerObj.main,
          correctDisplayAnswer: correctAnswerObj,
          isRepeated: false
        });

        currentQuestion.correctAnswers = currentQuestion.correctAnswers.filter(
          answerObj => answerObj.main !== correctAnswerObj.main
        );

        if (currentQuestion.correctAnswers.length === 0) {
          setInputValue('');
          setShowAllAnswersGiven(true);
          setTimeout(() => {
            setShowAllAnswersGiven(false);
            handleNextQuestion();
          }, 3000);
          new Audio(pingSound).play();
          return;
        }

        new Audio(pingSound).play();
      } else {
        currentGuesses.unshift({
          guess: inputValue,
          isCorrect: false,
          correctAnswer: '',
          correctDisplayAnswer: null,
          isRepeated: false
        });
        new Audio(buzzSound).play();
      }

      updatedGuesses[currentQuestionIndex] = currentGuesses;
      updatedQuestions[currentQuestionIndex] = currentQuestion;

      setAllGuesses(updatedGuesses);
      setQuestions(updatedQuestions);
      setInputValue('');
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex + 1 < questions.length) {
      const nextQuestionIndex = currentQuestionIndex + 1;
      showQuestionImage(nextQuestionIndex);
      setCurrentQuestionIndex(nextQuestionIndex);
      setTimeLeftPerQuestion(55);
      setShowCorrectAnswers(false);
    } else {
      setGameFinished(true);
    }
  };

  const calculateLevenshteinDistance = (a, b) => {
    const matrix = Array.from({ length: a.length + 1 }, () => Array(b.length + 1).fill(0));

    for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost
        );
      }
    }

    return matrix[a.length][b.length];
  };

  const handleFinishGame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStartGame(false);
      history.push('/games?game=6');
    }, 5000);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    if (timeLeftPerQuestion > 0 && !gameFinished) {
      const timerId = setTimeout(() => setTimeLeftPerQuestion(timeLeftPerQuestion - 1), 1000);
      return () => clearTimeout(timerId);
    } else if (timeLeftPerQuestion === 0) {
      setShowAllAnswersGiven(false);
      setShowCorrectAnswers(true);
      setIsAnswerInputDisabled(true);
      const correctAnswersDisplayTimeout = setTimeout(() => {
        setShowCorrectAnswers(false);
        setIsAnswerInputDisabled(false);
        handleNextQuestion();
      }, 6000);
      return () => clearTimeout(correctAnswersDisplayTimeout);
    }
  }, [timeLeftPerQuestion, gameFinished]);

  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} />
      </>
    );
  }

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }

  if (gameFinished) {
    const correctGuessesCount = allGuesses
      .flat()
      .filter(guessObj => guessObj && guessObj.isCorrect === true)
      .length;
    return (
      <GameResult
        score={correctGuessesCount}
        totalQuestions={questions.length * 4}
        onNextGame={handleFinishGame}
        isKeywordQuiz={true}
      />
    );
  }

  return (
    <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`}>
      <div className='highlight-current-game'> 5 </div>
      {startGame && (
        <div className="top-right-wooden-sign-container">
          <p>Raad zoveel mogelijk dingen bij het genoemde onderwerp passen!</p>
        </div>
      )}
      {!startGame ? (
        <VideoPlaceholder />
      ) : (
        <>
          <div className="game-two-content">
            <div className="game-two-section game-five-section">
              <h2>Open deur</h2>
              <div className="game-two-text">
                <p>{questions[currentQuestionIndex]?.question}</p>
              </div>

              <div className="game-guesses">
                {showCorrectAnswers || showAllAnswersGiven ? (
                  <ul className="correct-answers">
                    {showAllAnswersGiven ? (
                      <p style={{ color: 'green' }}>Alle antwoorden gegeven!</p>
                    ) : (
                      <p style={{ color: 'red' }}>Hier zijn de overgebleven antwoorden:</p>
                    )}
                    {questions[currentQuestionIndex].correctAnswers.map((answer, index) => (
                      <li key={index} style={{ color: 'green' }}>
                        ✔️ {answer.main}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ul className="game-five-ul">
                    {(allGuesses[currentQuestionIndex] || []).map((guessObj, index, arr) => (
                      <li className="list-guesses" key={index}>
                        {arr.length - index} - {guessObj.guess || ''}
                        {guessObj.isCorrect === true && (
                          <span style={{ color: 'green' }}> ✔️ {guessObj.correctDisplayAnswer?.main} </span>
                        )}
                        {guessObj.isCorrect === false && guessObj.isRepeated && (
                          <span style={{ color: 'red' }}> ❌ - Raad eens herhaald </span>
                        )}
                        {guessObj.isCorrect === false && !guessObj.isRepeated && (
                          <span style={{ color: 'red' }}> ❌ </span>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="game-two-image-section game-five-image-section">
              <div className="question-image-container">
                <img
                  src={modalImage}
                  alt=""
                  className="question-image"
                />
              </div>
              <div className="timer-progress-bar margin-btn">
                <div className="progress" style={{ width: `${(timeLeftPerQuestion / 55) * 100}%` }}></div>
              </div>
              <div className="info-container">
                <p className="info-text">
                  Wat weet je over <strong>{questions[currentQuestionIndex]?.keyword}</strong>?
                </p>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Typ je antwoord..."
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  maxLength={30}
                  disabled={gameFinished || isAnswerInputDisabled || !canAnswer}
                />
                <button className="submit-btn" onClick={handleSubmit} disabled={gameFinished || isAnswerInputDisabled || !canAnswer}>
                  &#9654;
                </button>
              </div>
            </div>
          </div>
        </>
      )
      }
    </div >
  );
};

export default KeywordQuiz;
