import React from 'react';

const completeList = [
    "DANK",
    "STEUN",
    "BEGRIP",
    "COMPLIMENT",
    "ERKENNING",
    "TROTS",
    "RESPECT",
    "LOYAAL",
    "EERLIJKHEID",
    "BLIJ"
];

function getRandomWords() {
    let lst = [];
    for (var i = 0; i < 10; i++) {
        let currItem = completeList[Math.floor(Math.random() * completeList.length)];
        if (lst.indexOf(currItem) === -1) {
            lst.push(currItem)
        } else {
            i--;
        }
    }
    return lst.sort((a, b) => { return b.length - a.length });
}
function WSGenerator(wordList, directions) {
    directions = [-4, -3, -1, 3, 4];
    this.gridSize = 1;
    this.gridArr = [];
    this.directions = (directions && directions.length) ? directions : [-4, -3, -1, 3, 4];
    this.wordList = (wordList && wordList.length) ? wordList : getRandomWords();
    this.wordList = this.wordList.map((item, i) => {
        return { text: item, index: i, found: false };
    })
    this.alreadyFound = [];
    this.startBox = null;
    this.endBox = null;
}

WSGenerator.prototype.setGridSize = function () {
    // Fixed grid size is already set to 13; no dynamic calculation needed
    this.gridSize = 13;
};

WSGenerator.prototype.getRandomRow = function () {
    return Math.floor(Math.random() * this.gridSize);
}

WSGenerator.prototype.getRandomColumn = function () {
    return Math.floor(Math.random() * this.gridSize);
}

WSGenerator.prototype.getRandomDirection = function () {
    return this.directions[Math.floor(Math.random() * this.directions.length)];
}

WSGenerator.prototype.initGrid = function () {
    let grid = [];
    for (let i = 0; i < this.gridSize; i++) {
        grid[i] = [];
        for (let j = 0; j < this.gridSize; j++) {
            grid[i][j] = "$$";
        }
    }
    this.gridArr = grid.slice();
    for (var i = 0; i < this.wordList.length; i++) {
        this.populateWord(this.wordList[i].text);
    }
    this.populateUnusedBoxes();
};


WSGenerator.prototype.initGrid = function () {
    let grid = [];
    for (let i = 0; i < this.gridSize; i++) {
        grid[i] = [];
        for (let j = 0; j < this.gridSize; j++) {
            grid[i][j] = "$$";
        }
    }
    this.gridArr = grid.slice();
    for (var i = 0; i < this.wordList.length; i++) {
        this.populateWord(this.wordList[i].text);
    }
    this.populateUnusedBoxes();
}

WSGenerator.prototype.isPlacable = function (word, start, end, direction, increment) {
    let i = 0, wordLength = word.length;
    let currI = start.x, currJ = start.y;
    while (currI >= 0 && currI < this.gridSize && currJ >= 0 && currJ < this.gridSize && i < wordLength && (this.gridArr[currI][currJ] === word[i] || this.gridArr[currI][currJ] === "$$")) {
        i++;
        switch (direction) {
            case -1: {
                currJ = currJ - 1;
                break;
            }
            case 1: {
                currJ++;
                break;
            }
            case -2: {
                currI--;
                break;
            }
            case 2: {
                currI++;
                break;
            }
            case 3: {
                currI++;
                currJ++;
                break;
            }
            case -3: {
                currI--;
                currJ--;
                break;
            }
            case 4: {
                currI++;
                currJ--;
                break;
            }
            case -4: {
                currI--;
                currJ++;
                break;
            }
            default: {

            }
        }
    }
    return i === wordLength;
}

WSGenerator.prototype.placeWord = function (word, start, end, direction, increment) {
    let i = 0,
        wordLength = word.length;
    let currI = start.x, currJ = start.y;
    while (i < wordLength) {
        this.gridArr[currI][currJ] = { letter: word[i], id: (currI + 1) + "-cell-" + (currJ + 1), used: false, hilighted: false };
        i++;
        switch (direction) {
            case -1: {
                currJ = currJ - 1;
                break;
            }
            case 1: {
                currJ++;
                break;
            }
            case -2: {
                currI--;
                break;
            }
            case 2: {
                currI++;
                break;
            }
            case 3: {
                currI++;
                currJ++;
                break;
            }
            case -3: {
                currI--;
                currJ--;
                break;
            }
            case 4: {
                currI++;
                currJ--;
                break;
            }
            case -4: {
                currI--;
                currJ++;
                break;
            }
            default: {

            }
        }

    }
}

WSGenerator.prototype.populateWord = function (word, attempt = 0, index = 0) {
    const MAX_ATTEMPTS = 100;
    if (attempt >= MAX_ATTEMPTS) {
        return;
    }
    let start = { x: this.getRandomRow(), y: this.getRandomColumn() };
    const diagonalDirections = [3, -3, 4, -4];
    let dir = index % 2 === 1 ?
        diagonalDirections[Math.floor(Math.random() * diagonalDirections.length)] :
        this.getRandomDirection();

    if (this.isPlacable(word, start, null, dir, null)) {
        this.placeWord(word.toUpperCase(), start, null, dir, null);
    } else {
        this.populateWord(word, attempt + 1, index);
    }
};

WSGenerator.prototype.populateUnusedBoxes = function () {
    let indexi;
    let indexj;
    for (indexi = 0; indexi < this.gridSize; indexi++) {
        for (indexj = 0; indexj < this.gridSize; indexj++) {
            if (this.gridArr[indexi][indexj] === "$$") {
                this.gridArr[indexi][indexj] = {
                    letter: WSGenerator.prototype.alphabets[Math.floor(Math.random() * 25)],
                    id: (indexi + 1) + "-cell-" + (indexj + 1),
                    used: false,
                    hilighted: false
                };
            }
        }
    }
}

WSGenerator.prototype.alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "R", "T", "U", "V", "W", "X", "Y", "Z"];

// Solver part
WSGenerator.prototype.getDirection = function (startObj, endObj) {
    var dir;
    let stRow = startObj.row,
        stCol = startObj.col,
        curRow = endObj.row,
        curCol = endObj.col;

    if (curRow === stRow && curCol !== stCol) {
        if (stCol < curCol) {
            dir = 1;
        } else {
            dir = -1;
        }
    } else if (curCol === stCol && curRow !== stRow) {
        if (stRow < curRow) {
            dir = 2;
        } else {
            dir = -2;
        }
    } else if (((curCol - stCol) === (curRow - stRow)) || ((stCol - curCol) === (stRow - curRow))) {
        if (stRow < curRow && stCol < curCol) {
            dir = 3;
        } else if (stRow > curRow && stCol > curCol) {
            dir = -3;
        }
    } else if (((stRow - curRow) === (curCol - stCol)) || ((curCol - stCol) === (curRow - curRow))) {
        if (stRow < curRow && stCol > curCol) {
            dir = 4;
        } else if (stRow > curRow && stCol < curCol) {
            dir = -4;
        }
    }
    return dir ? dir : 0;
}

WSGenerator.prototype.getStringBetweenPoints = function (startBox, endBox) {
    var dir;
    dir = this.getDirection(startBox, endBox);
    return this.getStringByRowCol(startBox, endBox, dir);
}

WSGenerator.prototype.getStringByRowCol = function (startBox, endBox, dir) {
    var returnedString = "";
    var cellIds = [];
    let str = startBox.row, stc = startBox.col,
        enr = endBox.row, enc = endBox.col;

    switch (dir) {
        case -1:
            {
                for (let k = stc; k >= enc; k -= 1) {
                    returnedString = returnedString + this.gridArr[str][k].letter;
                    cellIds.push([str, k]);
                }
                break;
            }
        case 1:
            {
                for (let k = stc; k <= enc; k += 1) {
                    returnedString = returnedString + this.gridArr[str][k].letter;
                    cellIds.push([str, k]);
                }
                break;
            }
        case -2:
            {
                for (let k = str; k >= enr; k -= 1) {
                    returnedString = returnedString + this.gridArr[k][stc].letter;
                    cellIds.push([k, stc]);
                }
                break;
            }
        case 2:
            {
                for (let k = str; k <= enr; k += 1) {
                    returnedString = returnedString + this.gridArr[k][stc].letter;
                    cellIds.push([k, stc]);
                }
                break;
            }
        case -3:
            {
                for (let k = str, j = stc; k >= enr && j >= enc; k -= 1, j -= 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k, j]);
                }
                break;
            }
        case 3:
            {
                for (let k = str, j = stc; k <= enr && j <= enc; k += 1, j += 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k, j]);
                }
                break;
            }
        case -4:
            {
                for (let k = str, j = stc; k >= enr && j <= enc; k -= 1, j += 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k, j]);
                }
                break;
            }
        case 4:
            {
                for (let k = str, j = stc; k <= enr && j >= enc; k += 1, j -= 1) {
                    returnedString = returnedString + this.gridArr[k][j].letter;
                    cellIds.push([k, j]);
                }
                break;
            }
        default: {

        }
    }
    return { str: returnedString, ids: cellIds };
};

WSGenerator.prototype.TestString = function (testStr) {
    var str = testStr,
        reverseStr = "",
        matched = false,
        reverseMatched = false,
        matchFound = false,
        reverseMatchFound = false;

    for (let i = 0; i <= str.length; i += 1) {
        reverseStr = str.substring(i, i + 1) + reverseStr;
    }
    matched = this.matchString(str);
    reverseMatched = this.matchString(reverseStr);


    if (matched) {
        matchFound = this.isAlreadyFound(testStr);
    }
    if (reverseMatched) {
        reverseMatchFound = this.isAlreadyFound(reverseStr);
    }

    if (matched && !matchFound) {
        return { found: false, str: testStr, match: true };
    } else if (reverseMatched && !reverseMatchFound) {
        return { found: false, str: reverseStr, match: true };
    } else if (matchFound && reverseMatchFound) {
        return { found: true, match: false };
    } else {
        return { found: false, match: false };
    }
}

WSGenerator.prototype.isAlreadyFound = function (str) {
    var count, found = false;
    for (count = 0; count < this.alreadyFound.length; count++) {
        if (str === this.alreadyFound[count]) {
            found = true;
            break;
        }
    }
    return found;
};


WSGenerator.prototype.matchString = function (str) {
    var matched = false;
    for (let count = 0; count < this.wordList.length; count++) {
        if (str.toUpperCase() === this.wordList[count].text.toUpperCase()) {
            matched = true;
            break;
        }
    }
    return matched;
};

WSGenerator.prototype.getBoxById = function (id) {
    let [row, col] = id.split("-cell-");
    row -= 1; // subtract for 0 based index
    col -= 1; // subtract for 0 based index
    return Object.assign({}, this.gridArr[row][col], { row: row, col: col });
};


//cell.js
class Cell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hilighted: false,
        };

        this.mouseOver = this.mouseOver.bind(this);
        this.mouseOut = this.mouseOut.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
    }

    mouseOver(evt) {
        if (this.props.isHovered) {
            this.setState({ hilighted: true }); // Highlight cell if hovered
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isHovered && !state.hilighted) {
            return { hilighted: true };
        }
        return null;
    }

    mouseOut(evt) {
        if (!this.props.isHovered) {
            this.setState({ hilighted: false });
        }
    }

    handleTouchStart(evt) {
        evt.preventDefault();
        this.props.selectionStart(evt);
    }

    handleTouchEnd(evt) {
        evt.preventDefault();
        this.props.selectionEnd(evt);
    }

    render() {
        let { cell, isHovered } = this.props;
        let currClass = "cell" + (isHovered || cell.used ? " hilighted" : "");
        return (
            <div
                className={currClass}
                id={cell.id}
                onMouseDown={this.props.selectionStart}
                onMouseUp={this.props.selectionEnd}
                onMouseOver={this.props.mouseOver}
                onMouseOut={this.mouseOut}
                onTouchStart={this.props.selectionStart}
                onTouchEnd={this.props.selectionEnd}
                onTouchMove={this.props.handleTouchMove}
            >
                {cell.letter}
            </div>
        );
    }
}

//row.js
class Row extends React.Component {
    render() {
        let row = this.props.row;
        return (
            <div className="row">
                {row.map((item, i) => (
                    <Cell
                        cell={item}
                        key={i}
                        selectionStart={this.props.selectionStart}
                        selectionEnd={this.props.selectionEnd}
                        mouseOver={this.props.mouseOver}
                        hasSelectionStarted={this.props.hasSelectionStarted}
                        isHovered={this.props.hoveredCells.includes(item.id)}
                    />
                ))}
            </div>
        );
    }
}

class Grid extends React.Component {
    constructor(props) {
        super(props);
        this.gridRef = React.createRef();
        this.ws = props.ws;
        this.ws.startBox = null;
        this.ws.endBox = null;
        this.state = {
            ws: this.ws,
            isSelecting: false,
            hoveredCells: [],
        };

        this.selectionStart = this.selectionStart.bind(this);
        this.selectionEnd = this.selectionEnd.bind(this);
        this.hasSelectionStarted = this.hasSelectionStarted.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
    }

    handleTouchMove = (evt) => {
        evt.preventDefault(); // Prevent scrolling behavior

        if (this.state.isSelecting) {
            const touch = evt.touches[0]; // Get the touch point
            const element = document.elementFromPoint(touch.clientX, touch.clientY); // Find the element under the touch point

            if (element && element.id && element.classList.contains('cell')) {
                const id = element.id;

                // Avoid adding duplicate hovered cells
                if (!this.state.hoveredCells.includes(id)) {
                    this.setState((prevState) => ({
                        hoveredCells: [...prevState.hoveredCells, id],
                    }));

                    // Update the `endBox` dynamically
                    this.ws.endBox = this.ws.getBoxById(id); // Dynamically update the end box
                }
            }
        }
    };

    componentDidMount() {
        this.gridRef.current.addEventListener("touchstart", this.handleTouchStart, { passive: false });
        this.gridRef.current.addEventListener("touchmove", this.handleTouchMove, { passive: false }); // Prevent scrolling
        window.addEventListener("mouseup", this.clearHoveredCells);
        window.addEventListener("touchend", this.clearHoveredCells);
    }

    componentWillUnmount() {
        this.gridRef.current.removeEventListener("touchstart", this.handleTouchStart);
        this.gridRef.current.removeEventListener("touchmove", this.handleTouchMove);
        window.removeEventListener("mouseup", this.clearHoveredCells);
        window.removeEventListener("touchend", this.clearHoveredCells);
    }

    clearHoveredCells = () => {
        this.setState({ hoveredCells: [], isSelecting: false });
    };

    selectionStart(evt) {
        if (evt.touches) {
            evt = evt.touches[0]; // Normalize for touch events
        }
        let id = evt.target.id;
        this.ws.startBox = this.ws.getBoxById(id);
        this.setState({ isSelecting: true, hoveredCells: [id] }); // Start selection
        return false;
    }

    selectionEnd(evt) {
        // Normalize the event for touch or mouse
        if (evt.changedTouches) {
            evt = evt.changedTouches[0]; // Use the first touch point
        }

        // If there are hovered cells, use the last hovered cell as the end box
        if (this.state.hoveredCells.length > 0) {
            const lastHoveredId = this.state.hoveredCells[this.state.hoveredCells.length - 1];
            this.ws.endBox = this.ws.getBoxById(lastHoveredId); // Set the endBox to the last hovered cell
        }

        // Proceed if both startBox and endBox are defined
        if (this.ws.startBox && this.ws.endBox) {
            // Get the string between the selected points
            const strObj = this.ws.getStringBetweenPoints(this.ws.startBox, this.ws.endBox);
            const str = strObj.str;
            if (str) {
                // Check if the selected string matches any word in the list
                const obj = this.ws.TestString(str);

                if (obj.match && !obj.found) {

                    // Mark the word as found and add it to the alreadyFound list
                    this.ws.alreadyFound.push(str);

                    this.ws.wordList.forEach((item) => {
                        if (
                            item.text.toLowerCase() === str.toLowerCase() ||
                            str.split("").reverse().join("").toLowerCase() === item.text.toLowerCase()
                        ) {
                            item.found = true; // Mark the word as found
                            if (this.props.onWordFound) {
                                this.props.onWordFound(item.text); // Notify parent component
                            }
                        }
                    });

                    // Mark the selected cells as "used"
                    strObj.ids.forEach((item) => {
                        const [i, j] = item;
                        this.ws.gridArr[i][j].used = true;
                    });
                } 
            }
        } 

        this.ws.startBox = null;
        this.ws.endBox = null;
        this.setState({
            isSelecting: false,
            hoveredCells: [],
        });
    }

    hasSelectionStarted() {
        return this.ws.startBox;
    }

    handleMouseLeave(evt) {
        if (this.state.isSelecting) {
            let id = evt.target.id;
            this.setState((prevState) => ({
                hoveredCells: [...prevState.hoveredCells, id],
            }));
        }
    }

    render() {
        // Determine the box size based on screen width
        let boxSize;
        if (window.innerWidth < 390) {
            boxSize = 25;
        } else if (window.innerWidth < 480) {
            boxSize = 30;
        } else if (window.innerWidth < 768) {
            // boxSize = 35;
        } else if (window.innerWidth < 968) {
            boxSize = 35;
        } else {
            boxSize = 40;
        }
        // Adjust grid size based on the box size
        let gridStyle = {
            width: (boxSize * this.props.ws.gridSize) + "px",
            borderRadius: "2px"
        };

        let gridArr = this.props.ws.gridArr.slice();
        return (
            <div id="root">
                <div className="grid" style={gridStyle} onTouchMove={(e) => e.preventDefault()} ref={this.gridRef}>
                    {gridArr.map((row, i) => (
                        <Row
                            row={row}
                            rowIndex={i}
                            key={i}
                            selectionStart={this.selectionStart}
                            selectionEnd={this.selectionEnd}
                            mouseOver={this.handleMouseLeave}
                            hasSelectionStarted={this.hasSelectionStarted}
                            hoveredCells={this.state.hoveredCells}
                        />
                    ))}
                </div>

            </div>
        );
    }
}

class WordSearchGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.wsGenerator = new WSGenerator();
        this.wsGenerator.setGridSize();
        this.wsGenerator.initGrid();
        this.wsGenerator.populateUnusedBoxes();
    }

    onWordFound(word) {
        if (this.props.onWordFound) {
            this.props.onWordFound(word);
          }
        if (this.props.onGameEvent) {
            this.props.onGameEvent(`Word found: ${word}`);
        }
    }

    render() {
        return (
            <div id="root-container">
                <Grid ws={this.wsGenerator} onWordFound={this.onWordFound.bind(this)} />
            </div>)
    }
}

export default WordSearchGame;