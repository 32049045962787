import React, {useContext, useEffect, useState} from "react";
import {TcmsContext} from "../../../contexts/TcmsContext";
import TcmsSpan from "../../../tcms/Tags/TcmsSpan";
import TcmsDiv from "../../../tcms/Tags/TcmsDiv";
import Tcms from "../../../tcms/Tcms";

import {FilterContext} from "../../../contexts/FilterContext";
import {BreadCrumb} from "primereact/breadcrumb";
import {useHistory} from "react-router-dom";

const Breadcrumbs = ({product_id}) => {

  const [items, setItems] = useState([])

  const context = useContext(TcmsContext)
  const filterContext = useContext(FilterContext)

  const history = useHistory()

  const defaultBgImage = context.mobile ? `/media/topbar.svg` : `/media/topbar.svg`

  useEffect(() => {
    // Wait
    if (!filterContext.activeHoofdCatId) return
    let product
    let hoofdCat
    let subCat


    // Have 2 modes: PDP mode and Webshop mode
    if (product_id) {
      // PDP mode
      product = context.products.products.find(el => el.id === product_id)
      subCat = context.products.categories.find(el => el.id === product.category_id)

      if (subCat.parent_id !== 4) {
        hoofdCat = context.products.categories.find(el => el.id === subCat.parent_id)
      }
    } else {
      // Webshop mode
      hoofdCat = context.products.categories.find(el => el.id === filterContext.activeHoofdCatId)
      subCat = context.products.categories.find(el => el.id === filterContext.activeSubCats[0])
    }
    // Now the shitty thing...
    if(!hoofdCat){
      hoofdCat = subCat
      subCat = undefined
    }

    // Home
    let items = [{
      label: <TcmsSpan className="p-menuitem-text">Shop</TcmsSpan>,
      command: () => {
        filterContext.clear()
        history.push(`/${context.webshopSlug}/webshop`)
      }
    }]

    // Hoofdcat
    if (hoofdCat) {
      items.push({
        label: <TcmsSpan className="p-menuitem-text">{hoofdCat.name}</TcmsSpan>,
        command: () => {
          filterContext.setActiveHoofdCatId(hoofdCat.id)
          filterContext.setActiveSubCats([])
          history.push(`/${context.webshopSlug}/webshop`)
        }
      })
    }

    // Subcat
    if (subCat) {
      items.push({
        label: <TcmsSpan className="p-menuitem-text">{subCat.name}</TcmsSpan>,
        command: () => {
          filterContext.setActiveHoofdCatId(subCat.parent_id)
          filterContext.setActiveSubCats([subCat.id])
          history.push(`/${context.webshopSlug}/webshop`)
        }
      })
    }

    // Product
    if (product) {
      items.push({
        label: product.display_name
      })
    }

    setItems(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.products.categories, context.webshopSlug, filterContext])


  return (
    <div style={{
      backgroundImage: `url('${Tcms(defaultBgImage)}')`,
    }} className="breadcrumb-container p-breadcrumbs">
      {context.editMode &&
      <TcmsDiv style={{
        backgroundImage: `url('${defaultBgImage}')`,
      }} className="tcms-bg-image-picker"/>}
      <div>
        <BreadCrumb model={items}/>
      </div>
    </div>
  )
}

export default Breadcrumbs
