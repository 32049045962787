import React, { Suspense } from 'react'
import Auth from '../auth/Auth';
import { TcmsContext } from '../contexts/TcmsContext'
import { sendcloud, getHousenumberOutOfStreet } from "../services/sendcloud";
import PageLoading from "../components/webshop/PageLoading";
import axios from "axios";

// Need all them skins definded
// const SkinDefault = React.lazy(() => import('./skins/default/PersonalData'));
import SkinDefault from './skins/default/PersonalData'

const BE = process.env.REACT_APP_MTG_BACKEND

class PersonalData extends Auth {

  static contextType = TcmsContext

  authhash = '';

  state = {
    out_of_stock_visible: false, // This is the popup
    laters: 0,
    giveaway: 0,
    pickup: 0,
    shop_modus: false,
    serach_zip: '',
    carrier_slug: '',
    carrier_name: 'postnl',
    zipCheckError: '',
    cycloonCheck: 0,
    orderError: '',
    blockEmail: false,
    data: {
      firstname: '',
      lastname: '',
      private_email: '',
      zip: '',
      huisnummer: '',
      toevoeging: '',
      straatnaam: '',
      city: '',

      country_id: 166, // @todo .... from backend / config,
      delivery_address_id: 0,
      local_cart_delivery_time: null,  // @todo .... Calculate maybe
      local_cart_gif_away: '',
      gift_from: '',
      gift_from_text: '',
      min_delivery_date: '',
      algemene_voorwaarden: 1,
      medewerker_enquete: 0

    },
    carrier: {
      sp_id: '',
      sp_name: '',
      sp_address: '',
      sp_full_address: '', // sp_full_address is just UX
      sp_house_number: '',
      sp_zip: '',
      sp_city: '',
      sp_country: ''
    },
    laravelErrors: {}
  }

  constructor(props) {
    super(props);
    // These functions will be passed as props. Guess one should do like so in that case
    this.dataChanged = this.dataChanged.bind(this)
    this.doZipCheck = this.doZipCheck.bind(this)
    this.getUser = this.getUser.bind(this)
    this.refDialogOutOfStock = React.createRef()
  }

  mtgComponentDidMount() {
    window.scrollTo(0, 0);
    this.getUser()
    const giftFrom = sessionStorage.getItem("gift_from");
    const giftFromText = sessionStorage.getItem("gift_from_text");
    if (giftFromText) {
      let nu = { ...this.state.data };
      nu.gift_from_text = giftFromText;
      nu.gift_from = giftFrom;
      this.setState({ 'giveaway': 1 })
      this.setState({ data: nu });
    }
  }

  setJwtToken() {
    const jwt_token = sessionStorage.getItem('jwt_token');
    if (jwt_token) axios.defaults.headers.common['MtgJwt'] = jwt_token;
  }

  getUser() {
    this.setJwtToken();
    // Gets userdata from server and prefills form
    axios.get(BE + '/api/webshop/voucher').then(usr => {
      const u = usr.data
      let nu = { ...this.state.data }
      nu.firstname = u.firstname || ''
      nu.lastname = u.lastname || ''
      nu.private_email = u.private_email || ''
      nu.zip = u.zip || ''
      nu.huisnummer = u.sc_street_number || ''
      nu.toevoeging = u.street2 || ''
      nu.straatnaam = u.street || ''
      nu.city = u.city || ''
      nu.country_id = u.country_id || ''
      this.setState({ data: nu })
      
      if (u.private_email  && this.context.meta.wso_id === 989) {
        this.setState({'blockEmail': true});
      }
    })
  }

  searchDeliveryPoint() {
    sendcloud(this.state.carrier_slug, this.state.serach_zip, (data) => {

      let copy = Object.assign({}, this.state.carrier)

      if (this.state.carrier_slug === 'homerr') {
        const hn = getHousenumberOutOfStreet(data.street)
        copy.sp_house_number = hn.no + ' ' + hn.add
      } else {
        copy.sp_house_number = data.house_number
      }

      copy.sp_id = data.id
      copy.sp_name = data.name
      copy.sp_address = data.street
      // sp_full_address is just UX
      copy.sp_full_address = data.street + ' ' + data.house_number
      copy.sp_zip = data.postal_code
      copy.sp_city = data.city
      copy.sp_country = data.country

      this.setState({ carrier: copy })
    })
  }

  placeOrder() {

    // Block orders to prevent sending multiple
    this.context.setBlockOrder(true)

    // Clear this one so we do another zipcheck when needed
    this.doZipCheckLastCall = ''
    const format = (d) => {
      return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2)
    }

    axios.post(BE + '/api/webshop/place-order',
      // This is the data the backend needs..
      // send like this so easy to use laravel form validatioin
      {
        // Control flags
        pickup: this.state.pickup,
        carrier_slug: this.state.carrier_slug,
        carrier_name: this.state.carrier_name,
        laters: this.state.laters,
        giveaway: this.state.giveaway,
        shop_modus: this.context.shopModus,
        // User Data
        ...this.state.data,
        // PostNL Homerr data
        ...this.state.carrier,
        // Extra stuff
        gift_from: (this.state.giveaway === 1 ? this.state.data.gift_from : ''),
        gift_from_text: (this.state.giveaway === 1 ? this.state.data.gift_from_text : ''),
        min_delivery_date: (this.state.laters === 1 ? format(this.state.data.local_cart_delivery_time) : ''),

      }
    ).then((res) => {
      if (!res.data.jwt_token) {
        return
      }

      const orderData = {
        pickup: this.state.pickup,
        carrier_slug: this.state.carrier_slug,
        carrier_name: this.state.carrier_name,
        laters: this.state.laters,
        giveaway: this.state.giveaway,
        shop_modus: this.context.shopModus,
        ...this.state.data,
        ...this.state.carrier,
      };
    
      sessionStorage.setItem('orderData', JSON.stringify(orderData));
      // Clear all errors
      this.setState({laravelErrors: {}})
      this.context.setBlockOrder(false)
      // add remove etc will generate new token
      this.context.renewJwt(res.data.jwt_token)
      this.context.reloadWebshopContext();

      // And jump to bestellingn
      // this.props.history.push('/' + this.context.webshopSlug + '/bestellingen');
      this.getRows();
      this.props.history.push('/' + this.context.webshopSlug + '/bedankt');

    }).catch((err) => {

      // Deblock order so we can change some when constraints fire
      this.context.setBlockOrder(false)

      let errors = err.response.data.errors
      /**
       * You'll end up here when... Laravel gives back error messages.. as an array which makes sense
       * 1. Stuff is out of stock: i.e. errors.mtgjwt[0] = 'out_of_stock'
       * 2. There are field constraints i.e. errors.city[0] = 'The city field is required.'
       */

      // When some is out of stock need to open up the out of stock popup
      if (errors.mtgjwt && errors.mtgjwt[0] === 'out_of_stock')
        this.refDialogOutOfStock.current.open();

      // Move the constrains to the laravel errors (React state var)
      let laravelErrors = {}

      Object.entries(errors).forEach((val, i) => {
        // Make from Laravel default error response
        laravelErrors[val[0]] = val[1][0];

      });

      this.setState({laravelErrors})

    })

  }

  getRows() {
    axios.get(process.env.REACT_APP_MTG_BACKEND + '/api/be/report-nonsent-orders').then(response => {
      if (response.data && response.data.orders && response.data.orders.length > 200) this.sendTooManyOrdersInWaitStateEmail();
    });
  }

  sendTooManyOrdersInWaitStateEmail() {
    axios.get(`${process.env.REACT_APP_MTG_BACKEND}/api/webshop/mail/send-wait-list-email`);
  }

  setDeliveryAddressId(val) {
    let copy = Object.assign({}, this.state.data)
    copy.delivery_address_id = val
    this.setState({ data: copy })

  }

  setCountry(val) {
    let copy = Object.assign({}, this.state.data)
    copy.country_id = val
    this.setState({ data: copy }, () => this.doZipCheck(null, 'country_id'))

  }

  doZipCheckLastCall = '';
  doZipCheck(e, name) {
    let { data } = this.state
    if (data.zip && data.huisnummer && data.country_id === 166) {

      // Run only when changed
      const lastPayload = data.zip + data.huisnummer + data.country_id + data.toevoeging
      if (lastPayload !== this.doZipCheckLastCall) {
        this.doZipCheckLastCall = lastPayload

        this.setJwtToken();
        // Ruuun!
        axios.get(BE + '/api/webshop/zipcheck', {
          params: {
            zip: data.zip,
            huisnummer: data.huisnummer,
            toevoeging: data.toevoeging
          }
        }).then(res => {
          let copy = { ...this.state.data }
          // All good baby
          if (res.data.message === 'OK') {
            copy.straatnaam = res.data.address.street
            copy.city = res.data.address.city
            this.setState({ data: copy, zipCheckError: '' })
            this.setState({ cycloonCheck: res.data.cycloon })
          } else {
            // Zipcheck error from server
            copy.straatnaam = ''
            copy.city = ''
            this.setState({
              data: copy,
              zipCheckError: res.data.errors.valid_zip_housnumber_combo[0]
            })
          }
        })
      }
    } else {
      this.setState({ zipCheckError: '' })
    }

  }

  dataChanged(e, name) {
    let copy = Object.assign({}, this.state.data)
    let errs = Object.assign({}, this.state.laravelErrors)
    copy[e.target.id] = (e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value)
    errs[name] = null
    this.setState({ data: copy, laravelErrors: errs })
  }

  /*********************************************************************************************************************
   *                        The skin stuff. Do not change. Copy this part to create a new Skin page                    *
   *                        Yes, it's duplicated code. But I think Webpack needs it to load dynamic                    *
   *                        !! Only thing you might do is set pageType (public/private) !!                             *
   ********************************************************************************************************************/

  pageType = 'private'

  componentDidMount() {
    this.context.setLoadedComponent(this.constructor.name);
    super.componentDidMount()
    if (this.mtgComponentDidMount) this.mtgComponentDidMount()
    // this.getAuthHashDpgApi();
  }

  render() {

    if (!this.context.products) return null;

    return (
      <Suspense fallback={<PageLoading />}>{this.getSkin()}</Suspense>
    )
  }

  getSkin(html) {
    // Check if skin exists or theme exists
    const skinExists = eval('typeof Skin' + this.context.webshopId) === 'undefined' ? false : true // eslint-disable-line no-eval
    const themeExists = eval('typeof Theme' + this.context.themeId) === 'undefined' ? false : true // eslint-disable-line no-eval

    if (skinExists) {
      html = React.createElement(eval('Skin' + this.context.webshopId), { rootComponent: this }) // eslint-disable-line no-eval
    } else if (themeExists) {
      html = React.createElement(eval('Theme' + this.context.themeId), { rootComponent: this }) // eslint-disable-line no-eval
    } else {
      html = <SkinDefault rootComponent={this} />
    }

    return html
  }

}

export default PersonalData
