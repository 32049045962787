import React, { useContext, useEffect, useRef, useState } from 'react';
import Menu from "../../../components/webshop/menu/Menu";
import Banner from "../../../components/webshop/banner/Banner";
import TcmsH1 from "../../../tcms/Tags/TcmsH1";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import Background from '../../../components/webshop/background/Background';
import TcmsDropdown from "../../../tcms/Tags/TcmsDropdown";
import TcmsLabel from "../../../tcms/Tags/TcmsLabel";
import axios from "axios";
import { TcmsContext } from "../../../contexts/TcmsContext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { useLocation } from "react-router-dom";
import Footer from '../../../components/webshop/footer/Footer';

const BE = process.env.REACT_APP_MTG_BACKEND

export default function BuyCredit() {
    const context = useContext(TcmsContext)
    const { meta } = context
    const voucherSelectItems = []

    for (let i = 1; i <= 10; i++) {
        let amount = i * meta.eco_threshold
        voucherSelectItems.push({ name: amount, id: amount })
    };

    const messages = useRef(null);
    const [voucherAmount, setVoucherAmount] = useState(null);
    const [euroAmount, setEuroAmount] = useState(0);
    const [epp, setEpp] = useState(0);

    const onVoucherAmountChange = (e) => {
        setVoucherAmount(e.value);
        setEuroAmount(e.value.id * epp);
    }

    const urlsParams = useLocation().search;
    const payed = new URLSearchParams(urlsParams).get('payed') ?? null;

    useEffect(() => {

        const endpoint = '/api/webshop/payment/voucher-value'

        axios.get(BE + endpoint).then(res => {
            setEpp(res.data.euro_amount);
        })
    }, [context.jwt.usr.wid])

    useEffect(() => {
        if (payed) {
            const endpoint = '/api/webshop/payment/status'

            axios.get(BE + endpoint).then(res => {
                context.renewJwt(res.data.jwt_token);
                messages.current.show({
                    severity: 'success',
                    summary: 'De betaling is afgerond en de punten zullen binnen enkele minuten bijgeschreven worden.',
                    sticky: true
                });
            }).catch(() => {
                messages.current.show({
                    severity: 'warn',
                    summary: 'De betaling is niet geslaagd, probeer het nogmaals.',
                    life: 30000,
                });
            })
        }
    }, [payed, context])

    const buyCredits = () => {
        const data = {
            amount: voucherAmount.id
        }
        axios.post(BE + '/api/webshop/payment/start', data).then(res => {
            //setErrors({})
            if (res.data.url) {
                window.location.replace(res.data.url);
            }
        }).catch(() => {
            messages.current.show({
                severity: 'warn',
                summary: 'De betaling is niet geslaagd, probeer het nogmaals.',
                life: 30000,
            });
        })
    }
    return (
        <React.Fragment>
            <Menu />
            <Banner />
            <div className='page-wrapper'>
                <Background />
                <div className="container__large content-page page-info main-view">
                    <div className="p-grid">
                        <div className="p-col-12 text-center">
                            <TcmsH1>buy_credit_title</TcmsH1>
                        </div>
                        <div className="p-col-12 text-center">
                            <div><TcmsWYSIWYG>buy_credit_info</TcmsWYSIWYG></div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <Messages ref={messages} className="p-col-12 text-center" />
                        <div className="p-col-6 p-field">
                            <TcmsLabel className="p-col-6 p-text-right p-pr-4">Aantal punten</TcmsLabel>
                            <TcmsDropdown name="voucher_amount"
                                value={voucherAmount}
                                options={voucherSelectItems}
                                onChange={onVoucherAmountChange}
                                optionLabel="name"
                                placeholder="Maak uw keuze"
                                className="p-col-6" />
                        </div>
                        <div className="p-col-4 p-field">
                            <TcmsLabel className="p-col-6 p-text-right p-pr-4">Bedrag</TcmsLabel>
                            <InputNumber name="euro_amount"
                                value={euroAmount}
                                placeholder="selecteer het aantal punten"
                                disabled="disabled"
                                className="p-col-6"
                                mode="currency" currency="EUR" locale="nl-NL" />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-field p-col-12 p-col-md-3">
                            <Button label="Start betalen"
                                onClick={() => buyCredits()}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )

}



