import TcmsImg from "../../../tcms/Tags/TcmsImg";
import React, {useContext, useEffect, useRef, useState} from "react";
import {TcmsContext} from "../../../contexts/TcmsContext";
import {useHistory} from "react-router-dom";
import VirtualDialogFavorites from '../../virtual/dialogs/VirtualDialogFavorites'
import { Badge } from "primereact/badge";
import classNames from "classnames";
import TcmsSpan from "../../../tcms/Tags/TcmsSpan";

const Favorites = () => {
  const firstRender = useRef(true);
  const context = useContext(TcmsContext)
  const refVirtualDialogFavorites = useRef()
  const history = useHistory()
  const [hasRecentlyChanged,] = useState(false);

  useEffect(() => {
    if (firstRender.current) {
      return;
    }
    // Deprecated?
    // document.querySelector(".nav-top__counter").addEventListener("DOMSubtreeModified", function () {
    //     setHasRecentlyChanged(true)
    // });

    // setTimeout(() => {
    //   setHasRecentlyChanged(false);
    // }, 1000);
  },);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  });

  return <>
    <VirtualDialogFavorites ref={refVirtualDialogFavorites}/>
    <a href="/" onClick={(e) => {
      e.preventDefault()
      if(context.pageIsVM){
        refVirtualDialogFavorites.current.open()
      } else {
        history.push(`/${context.webshopSlug}/favorites`)
      }

    }}>
      <TcmsImg className="favorite"
               style={{width:'35px',height:'35px'}}
               src="/media/icons/favorite.svg"
               alt="Mijn favorieten"
      />
      <Badge className={classNames('nav-top__counter', {'active': hasRecentlyChanged})} value={context.favorites.length} severity="success"/>
      <TcmsSpan className="text">Favorieten</TcmsSpan>
    </a>

  </>

}
export default Favorites
