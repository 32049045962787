import React, {useEffect, useState} from 'react'
import axios from "axios";
import jwtDecode from "jwt-decode";
import hash from 'object-hash'

const primeflex = [1200, 992, 768, 576, 0]
const primeflexMap = ['xl', 'lg', 'md', 'sm', 'xs']
const globalIcons = {
  '1% For The Planet': '/media/icons/icon-planet.svg',
  'Duurzaam': '/media/icons/icon-duurzaam.svg',
  'Sociaal': '/media/icons/icon-sociaal.svg',
  'Lokaal': '/media/icons/icon-lokaal.svg',
  'Profit': '/media/icons/icon-planet.svg',
  'Planet': '/media/icons/icon-duurzaam.svg',
  'People': '/media/icons/icon-sociaal.svg',
  'Dokkum': '/media/icons/icon-1.svg',
  'Drachten': '/media/icons/icon-2.svg',
  'Harlingen': '/media/icons/icon-3.svg',
  'Heerenveen': '/media/icons/icon-4.svg',
  'Leeuwarden': '/media/icons/icon-5.svg',
  'Sneek': '/media/icons/icon-6.svg',
  'Vitaliteit': '/media/icons/icon-vitaliteit.svg',
  'Zero Waste': '/media/icons/icon-zerowaste.svg',
  '1% For Water for Life': '/media/icons/icon-waterforlife.svg',
  'Waterbesparing': '/media/icons/icon-waterbesparing.svg',
  'Healthy living': '/media/icons/icon-healthy.svg',
  'Samen genieten': '/media/icons/icon-samen.svg',
  'Iedereen meedoen': '/media/icons/icon-iedereen.svg',
  'Innovatie': '/media/icons/icon-innovatie.svg',
  'Utrechtse aanpak': '/media/icons/icon-utrecht.svg',
  'Amsterdam': '/media/icons/icon-amsterdam.svg',
  'Persoonlijke verzorging': '/media/icons/icon-verzorging.svg',
  'Fysieke vitaliteit': '/media/icons/icon-fysiek.svg',
  'Mentale vitaliteit': '/media/icons/icon-mentaal.svg',
  'Made in Europe': '/media/icons/icon-eu.svg',
  "EY's preferred products": '/media/icons/icon-ey.svg',
  "Inclusief": '/media/icons/icon-inclusief.svg',
  "Global Recycled Standard": "/media/icons/icon-global-recycled-standard.svg",
  "FSC Papier": "/media/icons/icon-fsc-papier.svg",
  "CE-Markering": "/media/icons/icon-ce-markering.svg",
  "Glasbak": "/media/icons/icon-glasbak.svg",
  "Papier Karton Kringloop": "/media/icons/icon-papier-karton-kringloop.svg",
  "FSC Recycled (Hout)": "/media/icons/icon-fsc-recycled-hout.svg",
  "FSC Recycled (Papier)": "/media/icons/icon-fsc-recycled-papier.svg",
  "Solar Keymark": "/media/icons/icon-solar.svg",
  "Certified B Corporation": "/media/icons/icon-certified-b.svg",
  "FSC 100% hout": "/media/icons/icon-fsc-recycled-hout.svg",
  "GloGlobal Organic Textile Standard": "/media/icons/icon-textiel.svg",
  "Dapper": "/media/icons/icon-dapper.svg",
  "Speels": "/media/icons/icon-speels.svg",
  "Eerlijk": "/media/icons/icon-eerlijk.svg",
  "Vertrouwen": "/media/icons/icon-vertrouwen.svg",
  "RE-ZIP herbruikbare verpakking": "/media/icons/icon-re-zip.svg",
  "750 jaar Amsterdam": "/media/icons/icon-750-jaar-amsterdam.svg",
  "KVK Top 100": "/media/icons/icon-kvk-top-100.svg",
}
const BE = process.env.REACT_APP_MTG_BACKEND
const TcmsContext = React.createContext({});
let runOnce = []
let translateCache = {}
const TcmsContextProvider = (props) => {

  // Mobile breakpoint
  const [mobile, setMobile] = useState()
  const [flex, setFlex] = useState()
  const [blockOrder, setBlockOrder] = useState(false)
  // Need the webshop slug (I don't know how to do it the React way but this one seems to work)
  const [webshopSlug, setWebshopSlug] = useState(null)
  // Some more global settings
  const [jwt, setJwt] = useState(null)  // !! This is the JWT cart and more.. Only has id's and counts and ecos
  const [cart, setCart] = useState([])  // !! This is the rich cart. It comes from a backend call
  const [orders, setOrders] = useState([])
  const [favorites, setFavorites] = useState([])
  const [themeId, setThemeId] = useState(null)

  // Virtual market
  const [virtualMarketSlug, setVirtualMarketSlug] = useState('')
  const [vmShowHelp, setVmShowHelp] = useState(true)
  const [pageIsVM, setPageIsVM] = useState(false) // When true you should be on the Virtual Market page
  const [pubquiz, setPubquiz] = useState([]);
  const [customHeader, setCustomHeader] = useState(false)
  const [welcomeScreenIntro, setWelcomeScreenIntro] = useState(false);
  const [ribbonIcons, setRibbonIcons] = useState(false);
  const [fontFamilyLink, setFontFamilyLink] = useState(null)
  const [fontFamilyValue, setFontFamilyValue] = useState(null)
  const [webshopId, setWebshopId] = useState(null)
  const [languageCode, setLanguageCode] = useState('nl_nl')
  const [translate, setTranslate] = useState(null)
  const [meta, setMeta] = useState(null)
  const [shipTo, setShipTo] = useState(null) // These are the countries we will ship to
  const [deliveryAddresses, setDeliveryAddresses] = useState(null)
  const [products, setProducts] = useState(null)

  const [loadedComponent, setLoadedComponent] = useState(null)
  // have public and private pages default private just in case
  const [pageType, setPageType] = useState(null)

  // Tcms
  const [isAdmin, setIsAdmin] = useState(false)
  const [editMode, setEditMode] = useState(false)

  // Shopmodus is when orders will not be send to Odoo
  const [shopModus, setShopModus] = useState(false)
  const [emailLogin, setEmailLogin] = useState(null)

  // Store the ref to the tcms popup
  const refTcmsDialog = props.refTcmsDialog

  const [reload, setReload] = useState(false)

  useEffect(() => {

    if (fontFamilyLink && !runOnce['fontFamilyLink']) {
      runOnce['fontFamilyLink'] = true
      /** Insert the Backend CSS files */
      let link = document.createElement('link')
      link.setAttribute('rel', 'stylesheet')
      link.setAttribute('href', fontFamilyLink)
      document.head.appendChild(link)
    }

    if (fontFamilyValue && !runOnce['fontFamilyValue']) {
      runOnce['fontFamilyValue'] = true
      /** Insert the style element for font family */
      let style = document.createElement('style')
      style.innerHTML = "body { font-family: "+fontFamilyValue+" !important ;}";
      style.innerHTML += "body div.top-menu-content button { font-family: "+fontFamilyValue+" !important;}";
      style.innerHTML += ".p-component { font-family: "+fontFamilyValue+" !important;}";
      document.head.appendChild(style)
    }

    /** Insert the Backend CSS file (with slug) */
    if (webshopSlug && !runOnce['link']) {
      runOnce['link'] = true
      let link = document.createElement('link')
      link.setAttribute('rel', 'stylesheet')
      link.setAttribute('href', `${BE}/css/${webshopSlug ? webshopSlug : 'zaffius'}/theme.css`)
      document.head.appendChild(link)

      let link2 = document.createElement('link')
      link2.setAttribute('rel', 'stylesheet')
      link2.setAttribute('href', `/assets/mtg-theme.css`)
      document.head.appendChild(link2)

    }
  }, [fontFamilyLink, webshopSlug, fontFamilyValue])

  const getBalance = () => {
    if (!cart || !cart.items) return 0
    /**
     * Calculate balance from the jwt token
     */
    return jwt.usr.eco - cart.items.reduce(function (a, b) {
      return a + (b.count * b.ecos)
    }, 0)
  }

  const canOrder = () => {
    if(blockOrder) {
      return false
    }
    return meta.round_order ? getBalance() === 0 : true
  }
  /**
   * Tcms super simple translate func
   */
  const stringTranslate = str => {
    const tstr = translate[str] ? translate[str] : str
    if(editMode) {
      return tstr
    }
    // normal behaviour
    return tstr === '{{blank}}' ? '' : tstr
  }

  const getTotalCartItems = () => {
    if (!cart || !cart.items) return 0
    return cart.items.reduce(function (a, b) {
      // When 'free' don't count
      return a + (b.ecos ? b.count : 0)
    }, 0)
  }

  useEffect(() => {
    function handleResize() {
      // Mobile yes no .. smaller lg in this case
      setMobile(window.innerWidth < primeflex[0])

      for (const [i, size] of primeflex.entries()) {
        if (window.innerWidth >= size) {
          setFlex(primeflexMap[i])
          break;
        }
      }
    }

    handleResize()
    // Adds the resize listener
    window.addEventListener('resize', handleResize);
    // When unmounts remove
    return () => window.removeEventListener('resize', handleResize);

  }, []);


  useEffect(() => {

    if (reload) {

      setReload(false)

    } else if (webshopId !== null && themeId !== null) {

      /**
       * We have public and private translate data
       *
       * private: contains the private translations, the products of the webshop
       * and the product categories and meta data about the webshop
       *
       * public: For now only the login page and contains only the translations for that page
       *
       */
      const newPageType = ['Login'].includes(loadedComponent) ? 'public' : 'private'

      /**
       * Translate per page type. This is security wise.
       * Login page should be public for instance while
       * the webshop and greeting and so should be secured
       */
      if (translateCache[newPageType + languageCode]) {
        // Cache the translations on global and
        setTranslate(translateCache[newPageType + languageCode])
      } else {
        axios.get(`${process.env.REACT_APP_MTG_BACKEND}/api/webshop/${newPageType}`, {
          params: {
            webshopId,
            themeId,
            languageCode
          }
        })
          .then((res) => {
            if ((res.data.meta.wso_id === 953 || res.data.meta.wso_id === 259) && res.data.meta.ribbons !== undefined)  {
              // res.data.meta.ribbons[0] = {id:2, name: "People"}
              // res.data.meta.ribbons[1] = {id:4, name: "Planet"}
              // res.data.meta.ribbons[2] = {id:11, name: "Profit"}
            }

            let noVmHelp = [600,513,512,483, 1091];//webshops to only show voorwoord

            if (noVmHelp.includes(res.data.meta.wso_id)) {
              setVmShowHelp(false)
            }

            if (res.data.meta.welcome_screen_intro) {
              setWelcomeScreenIntro(true);
            }

            if (res.data.meta.custom_header) {
              setCustomHeader(true);
            }

            // set translate and cache
            setTranslate(res.data.translate)
            translateCache[newPageType + languageCode] = res.data.translate
            setFontFamilyValue(res.data.meta.font_family)
            setFontFamilyLink(res.data.meta.font_family_link)
            setPageType(newPageType)
            setMeta(res.data.meta)
            setRibbonIcons(globalIcons)
            setFavorites( res.data.favorites ? res.data.favorites.split(',').map(el=>parseInt(el)) : [])
            setShipTo(res.data.ship_to)
            setDeliveryAddresses(
              [{id: 0, name: 'Kies een afleverpunt', type:'label'}]
                .concat(res.data.meta.delivery_addresses)
            )
            setOrders(res.data.orders)
            setCart(res.data.cart)
            setPubquiz(res.data.pubquiz)
            setEmailLogin(res.data.meta.email_login)
            /**
             * This one last
             * So in componentDidUpdate we can check for products
             */
            if (res.data.products) {

              setProducts({
                duration: res.data.duration,
                products: res.data.products,
                categories: res.data.categories
              })
            }
          })
      }
    }
  }, [webshopId, themeId, languageCode, loadedComponent, reload]);


  return (
    <TcmsContext.Provider value={{
      // Size
      mobile,
      // const
      webshopSlug,

      // State
      jwt,
      isAdmin,
      shopModus,
      editMode,
      themeId,

      virtualMarketSlug,
      pageIsVM,
      vmShowHelp,
      pubquiz,

      customHeader,
      welcomeScreenIntro,
      ribbonIcons,

      webshopId,
      products,
      cart,
      orders,
      favorites,

      languageCode,
      translate,
      meta,
      shipTo,
      deliveryAddresses,
      refTcmsDialog,

      pageType,         // Used in translate
      loadedComponent,  // Tell world we have loaded the correct translate
      flex,

      emailLogin,

      // Setters
      setJwt,
      setCart,
      setFavorites,
      setIsAdmin,
      setEditMode,
      setShopModus,
      setPageIsVM,
      setThemeId,
      setWebshopId,
      setWebshopSlug,
      setLanguageCode,
      setLoadedComponent, // Used in translate
      setFontFamilyLink,
      setFontFamilyValue,
      setVmShowHelp,
      setBlockOrder,
      setCustomHeader,
      setWelcomeScreenIntro,
      setRibbonIcons,
      getBalance,
      canOrder,
      getTotalCartItems,

      storage: process.env.REACT_APP_MTG_BACKEND + '/storage',

      // Very simple
      stringTranslate,

      // Methods
      reloadWebshopContext: () => {
        translateCache = {} // This is not a state var
        setReload(true) // This is a state var and triggers a reload
      },
      login: (jwt_token) => {

        // if we get on, decode and store
        let token = jwt_token ? jwtDecode(jwt_token) : null

        // bail out when not changed
        if (!token || (hash(token) === hash(jwt))) return

        setJwt(token)
        setLanguageCode(token.usr.loc)
        setWebshopId(token.usr.wid)
        setWebshopSlug(token.usr.slu)
        setThemeId(token.usr.tid)
        setVirtualMarketSlug(token.usr.vim)
        translateCache = {}
      },

      logout: () => {
        // userId and jwt_token
        setJwt(null);
        setCart([])
        translateCache = {}
        sessionStorage.removeItem('jwt_token');
      },
      renewJwt: (jwt_token) => {

        const old_jwt = jwtDecode(sessionStorage.getItem('jwt_token', jwt_token))

        // Reset jwt token
        sessionStorage.setItem('jwt_token', jwt_token);
        axios.defaults.headers.common['MtgJwt'] = jwt_token;
        // And will update the context
        let jwt = jwtDecode(jwt_token)
        setJwt(jwt)
        setLanguageCode(jwt.usr.loc)

        // detect lang swicth and if so get prods
        if (old_jwt.usr.loc !== jwt.usr.loc) {
          translateCache = {}
        }
      },
      setJwtForGames: (jwt_token) => {
        let token = jwt_token ? jwtDecode(jwt_token) : null;
        if (!token || (hash(token) === hash(jwt))) return;
        setJwt(token);
    }
    }}>
      {props.children}
    </TcmsContext.Provider>
  )
}

export {TcmsContext, TcmsContextProvider}
