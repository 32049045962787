import React, {useContext} from 'react'
import {TcmsContext} from "../../../contexts/TcmsContext";
import { useHistory } from 'react-router-dom';
import Cart from "./Cart";
import Tcms from "../../../tcms/Tcms";


export default function MenuCart(props) {

  const history = useHistory();
  const context = useContext(TcmsContext)

  const rezipProducts = [107734,107735]

  const isItemRezipProduct = (id) => {
    if (rezipProducts.includes(id)) {
      return true;
    }
    return false;
  }


  let buttonTitle = "Afronden";
  let orderDisabled = !context.canOrder()
  context.cart.items.map(item => {
    if (item.avail.status !== "ok") {
      if (!isItemRezipProduct(item.id)) {
        orderDisabled = true;
        buttonTitle = "Pas eerst je winkelmand aan. (Sommige producten hebben te weinig voorraad)";
      }
    }
    return true;
  });

  
  return (
    <div className="menu-cart cart__content">
      <h3 className="cart__heading"><Tcms>Winkelmand</Tcms></h3>
      <div className="ecos-left cart__ecos">
        <Tcms>Aantal eco's over</Tcms>
        <span>{context.getBalance()}</span>
      </div>

      <Cart/>

      <div className="cart__bottom">
        <Tcms>verzending_vertraging</Tcms>
        <div className="buttons">
          <button
            disabled={orderDisabled}
            title={buttonTitle}
            className="p-button p-component"
            onClick={()=>{ history.push(`/${context.meta.slug}/gegevens`)}}>
            <Tcms>Afronden</Tcms>
          </button>
        </div>
        <div className="buttons">
          <button
            className="p-button p-component"
            onClick={()=>{ history.push(`/${context.meta.slug}/webshop`)}}>
            <Tcms>Verder winkelen</Tcms>
          </button>
        </div>
      </div>

    </div>
  )
}
