import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import Player from '@vimeo/player';
import "./VideoPlaceholder.scss";

const VideoPlaceholder = ({ setIsMusicMuted }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentGame = searchParams.get('game');

  const videoUrls = {
    1: 'https://player.vimeo.com/video/1034320640',
    2: 'https://player.vimeo.com/video/1034320907',
    3: 'https://player.vimeo.com/video/1034321116',
    4: 'https://player.vimeo.com/video/1034321444',
    5: 'https://player.vimeo.com/video/1034321644',
    6: 'https://player.vimeo.com/video/1034322016',
  };

  const videoUrl = videoUrls[currentGame] || videoUrls[1];
  const startGameUrl = `${location.pathname}?game=${currentGame}&start=true`;

  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [isSkipped, setIsSkipped] = useState(false);
  const iframeRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    const iframe = document.querySelector('iframe');
    const player = new Player(iframe);

    player.on('play', () => {
      if (typeof setIsMusicMuted === 'function') {
        setIsMusicMuted(true);
      }
    });

    player.on('ended', () => {
      setIsVideoEnded(true);
     
    });

    return () => {
      player.off('play');
      player.off('ended');
      if (typeof setIsMusicMuted === 'function') {
        setIsMusicMuted(false);
      }
    };
  }, [setIsMusicMuted]);

  useEffect(() => {
    const calculateLeftPosition = async () => {
      const iframe = iframeRef.current;
      const overlay = overlayRef.current;
      if (iframe && overlay) {
        const iframeRect = iframe.getBoundingClientRect();
        const videoAspectRatio = 16 / 9;
        const videoHeight = iframeRect.width / videoAspectRatio;
        let videoWidth = iframeRect.width;
        if (videoHeight > iframeRect.height) {
          videoWidth = iframeRect.height * videoAspectRatio;
        }
        const left = (iframeRect.width - videoWidth) / 2;
        const top = (iframeRect.height - videoHeight) / 2;

        overlay.style.left = `${left}px`;
        overlay.style.top = `${top}px`;
      }
    };

    calculateLeftPosition();
    window.addEventListener("resize", calculateLeftPosition);

    return () => {
      window.removeEventListener("resize", calculateLeftPosition);
    };
  }, []);

  return (
    <>
      {(isVideoEnded || isSkipped) && (
        <a href={startGameUrl} className={`games-button-green start-game-button ${isVideoEnded || isSkipped ? 'highlight' : ''}`}>
          Start spel {currentGame}
        </a>
      )}
      <div className="video-placeholder">
        <div className={`video-container ${isVideoEnded ? 'blurred' : ''}`}>
          <iframe
            ref={iframeRef}
            src={videoUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay; fullscreen; encrypted-media"
            allowFullScreen
            title={`Game ${currentGame} Introduction Video`}
          ></iframe>
          <div ref={overlayRef} className="highlight-current-game-video">
            {currentGame}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPlaceholder;
