import React from "react";
import "./GameIntro.scss";
import BeeGameIntro from '../assets/game-intro-imgs/Game-2.png';
import FlagAndSportQuizIntro from '../assets/game-intro-imgs/Game-1.png';
import BobMarleyQuizIntro from '../assets/game-intro-imgs/Game-3.png';
import AnagramGameIntro from '../assets/game-intro-imgs/Game-4.png';
import WordSearchIntro from '../assets/game-intro-imgs/Game-6.png';
import KeywordQuizIntro from '../assets/game-intro-imgs/Game-5.png';

const gameImages = {
  1: FlagAndSportQuizIntro,
  2: BeeGameIntro,
  3: BobMarleyQuizIntro,
  4: AnagramGameIntro,
  5: KeywordQuizIntro,
  6: WordSearchIntro
};

const GameIntro = ({
  title,
  subTitle,
  gift,
  description,
  instruction,
  showMedals = false,
  onStart
}) => {

  const urlParams = new URLSearchParams(window.location.search);
  const gameNumber = parseInt(urlParams.get("game"), 10);
  const backgroundImage = { backgroundImage: `url(${gameImages[gameNumber]})` };

  return (
    <div className="game-intro-container">
      <div className="game-intro-content">
        <div className="intro-text-container">
          <h1 className="intro-title">{title}</h1>
          {showMedals && (
            <div className="medal-icons">
              <span role="img" aria-label="gold-medal" className="medal gold-medal">🥇</span>
              <span role="img" aria-label="silver-medal" className="medal silver-medal">🥈</span>
              <span role="img" aria-label="bronze-medal" className="medal bronze-medal">🥉</span>
            </div>
          )}
          {subTitle && (
            <p 
              className="intro-subtitle-text" 
              dangerouslySetInnerHTML={{ __html: subTitle }} 
            />
          )}
        {gift && (
          <p 
            className="intro-description" 
            dangerouslySetInnerHTML={{ __html: gift }} 
          />
        )}
        <p className="intro-description" dangerouslySetInnerHTML={{ __html: description }}></p>
          {instruction && <p className="intro-instruction">{instruction}</p>}
          <button className="prim-btn bounce" onClick={onStart}>
            Start de uitdaging
          </button>
        </div>

        {/* Image Placeholder with Dynamic Background */}
        <div className="intro-image-placeholder">
          <div className="image-circle" style={backgroundImage}></div>
        </div>
      </div>
    </div>
  );
};

export default GameIntro;