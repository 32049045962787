import { useContext } from "react";
import { TcmsContext } from "../../../contexts/TcmsContext";
import Tcms from "../../../tcms/Tcms";

const Spotify = () => {
  const context = useContext(TcmsContext)

  return <>
    <div>
      {context.editMode &&
        <>
          <div style={{ padding: '1rem' }}>
            Bepaal hier de playlist: <Tcms>playlist/37i9dQZF1DZ06evO1s6BXO</Tcms>
          </div>
        </>
      }

      <iframe title="Spotify" src={`https://open.spotify.com/embed/${context.stringTranslate('playlist/37i9dQZF1DZ06evO1s6BXO')}`}
        width="100%"
        height="380"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      >
      </iframe>
    </div>
  </>
}

export default Spotify
