import React, { useContext, useEffect, useState } from 'react'
import { TcmsContext } from './TcmsContext'

const FilterContext = React.createContext({});

const FilterContextProvider = ({ children }) => {
  const context = useContext(TcmsContext)
  const [sort, setSort] = useState('custom')
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 })
  const [maxPrice, setMaxPrice] = useState(0)
  const [ribbons, setRibbons] = useState([])
  const [defaultHoofdCatId, setDefaultHoofdCatId] = useState(4)
  const [activeHoofdCatId, setActiveHoofdCatId] = useState()
  const [customRibbon, setCustomRibbon] = useState()
  const [activeSubCats, setActiveSubCats] = useState([])
  const [search, setSearch] = useState()
  const [filteredProductCount, setFilteredProductCount] = useState(666)
  /**
   * Set the default Hoofdcat and set Min max price range
   */
  useEffect(() => {
    // Wait for it
    if (!context.products?.categories || context.products.categories.length === 0) return
    // Set price range
    let range = { min: 999999, max: 0 }
    context.products.products.forEach(el => {
      if (el.price_in_eco < range.min) {
        range.min = el.price_in_eco
      }
      if (el.price_in_eco > range.max) {
        range.max = el.price_in_eco
      }
    })

    setPriceRange(range)
    setMaxPrice(range.max)
  }, [context.products?.categories, context.products?.products])

  return <FilterContext.Provider value={{
    sort,
    defaultHoofdCatId,
    activeHoofdCatId,
    activeSubCats,
    priceRange,
    maxPrice,
    filteredProductCount,
    ribbons,
    search,
    customRibbon,

    setSort,
    setDefaultHoofdCatId,
    setActiveHoofdCatId,
    setActiveSubCats,
    setMaxPrice,
    setFilteredProductCount,
    setRibbons,
    setSearch,
    setCustomRibbon,
    clear: () => {
      setActiveHoofdCatId(defaultHoofdCatId)
      setActiveSubCats([])
      setMaxPrice(priceRange.max)
      setSearch(null)
    }
  }}>
    {children}
  </FilterContext.Provider>
}

export { FilterContext, FilterContextProvider }
