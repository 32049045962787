import React, { useState, useEffect, useRef, useContext } from "react";
import "./BeeGame.scss";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import GameIntro from '../game-intro/GameIntro';
import GameResult from '../game-result/GameResult';
import { useHistory } from 'react-router-dom';
import { storeGameData, getGameScoresByUser, updateGameData } from "../services/gameProgressService";
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import gamesTransitionVideo from '../assets/games_transition.mp4';
import pingSound from '../../../../sounds/ping.mp3';
import buzzSound from '../../../../sounds/softbuzz.mp3';
import axios from "axios";

const BeeGame = ({ startGame, setStartGame, fetchScore }) => {
  const { setJwtForGames } = useContext(TcmsContext);
  const [progress, setProgress] = useState(100);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(20);
  const [feedback, setFeedback] = useState(false);
  const [beePosition, setBeePosition] = useState(100);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [shake, setShake] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [correctOption, setCorrectOption] = useState(null);
  const history = useHistory();
  const [userId, setUserId] = useState('');
  const [shopId, setShopId] = useState('');
  const [existingGameId, setExistingGameId] = useState(null);
  const gameId = 4;

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      axios.defaults.headers.common['MtgJwt'] = storedJwtToken;
      const tokenData = jwtDecode(storedJwtToken);
      setUserId(tokenData?.usr?.uid);
      setShopId(tokenData?.usr?.wid);
      setJwtForGames(storedJwtToken);
    }
  }, [setJwtForGames]);

  // Fetch existing game progress to check for existing entries
  useEffect(() => {
    const fetchGameProgress = async () => {
      try {
        const progress = await getGameScoresByUser(userId);
        const gameEntry = progress.result.find(
          (entry) => entry.game_id === gameId && entry.webshoporder_id === shopId
        );
        if (gameEntry) {
          setExistingGameId(gameEntry.id);
        }
      } catch (error) {
        console.error('Error fetching game progress:', error.message);
      }
    };
    if (userId) {
      fetchGameProgress();
    }
  }, [userId, shopId]);

  const [isClickable, setIsClickable] = useState(true);
  const timerRef = useRef(null);

  const gameIntroText = {
    title: 'Naam spel: De achtervolging',
    subTitle: '<strong>Doel</strong>: Geef zoveel mogelijk goede antwoorden op de vragen over biodiversiteit, bijen en atletiek. Blijf de bijen voor!',
    gift: '<strong>Centraal cadeau bij dit spel</strong>',
    description: `Na het spel kun je in de webshop het Capi Biodiversity Bee Hotel als cadeau kiezen.
      Dit functionele en stijlvol vormgegeven bijenhotel biedt solitaire bijen een veilige nestplaats, waardoor je zelf actief bijdraagt aan de biodiversiteit in jouw omgeving! Gemaakt van duurzame materialen is het bijenhotel eenvoudig te bevestigen aan bijvoorbeeld een stok of regenpijp.
    Een mooie en milieubewuste keuze voor elke tuinliefhebber.`,
    showMedals: false
  };

  const questions = [
    {
      question: 'Wat is de levensduur van een werkbij?',
      answer: 'A',
      options: ['Enkele weken', 'Enkele maanden', 'Enkele jaren']
    },
    {
      question: 'Hoeveel procent van de dieren en planten is bedreigd met uitsterven?',
      answer: 'C',
      options: ['5%', '10%', '25%']
    },
    {
      question: 'Hoeveel nectar heeft een bij nodig om 1 kilo honing te maken?',
      answer: 'C',
      options: ['1 kilo', '10 kilo', '20 kilo']
    },
    {
      question: 'Hoeveel atleten nemen meestal deel aan de finale van de 100 meter sprint op de Olympische Spelen?',
      answer: 'B',
      options: ['6', '8', '10']
    },
    {
      question: 'Hoeveel soorten bijen zijn er wereldwijd?',
      answer: 'A',
      options: ['20.000', '50.000', '100.000']
    },
    {
      question: 'Wat produceren bijen als ze nectar omzetten?',
      answer: 'B',
      options: ['Suiker', 'Honing', 'Meel siroop']
    },
    {
      question: 'Welke atleet heeft de meeste gouden medailles gewonnen in de Olympische geschiedenis van de 100 meter sprint?',
      answer: 'A',
      options: ['Usain Bolt', 'Carl Lewis', 'Jesse Owens']
    },
    {
      question: 'Welk ecosysteem heeft de meeste biodiversiteit?',
      answer: 'B',
      options: ['Woestijn', 'Regenwoud', 'Toendra']
    },
    {
      question: 'Hoe communiceren bijen met elkaar?',
      answer: 'B',
      options: ['Door geluiden', 'Door dansen', 'Door kleuren']
    },
    {
      question: 'Wie was de eerste sprinter die de 9 seconden grens op de 100 meter brak?',
      answer: 'A',
      options: ['Jim Hines', 'Ben Johnson', 'Carl Lewis']
    },
    {
      question: 'Hoeveel ogen heeft een bij?',
      answer: 'C',
      options: ['2', '4', '5']
    },
    {
      question: 'Wat wordt het koningsgetal van de atletiek op de Olympische Spelen genoemd?',
      answer: 'A',
      options: ['100 meter', '200 meter', '400 meter']
    },
    {
      question: 'Wat is de belangrijkste fysiologische aanpassing die sprinters nastreven in hun training?',
      answer: 'C',
      options: ['Verbeterde aerobe capaciteit', 'Verhoogde anaerobe drempel', 'Verbeterde spierkracht en explosiviteit']
    },
    {
      question: 'Welk percentage van de dieren en planten wordt met uitsterven bedreigd?',
      answer: 'C',
      options: ['5%', '12%', '28%']
    },
    {
      question: 'Waarom zijn bijen essentieel voor de biodiversiteit?',
      answer: 'B',
      options: ['Ze produceren honing die mensen eten', 'Ze bestuiven bloemen en gewassen', 'Ze beschermen planten tegen insectenplagen']
    },
    {
      question: 'Hoe kun je bijen in je eigen omgeving helpen?',
      answer: 'A',
      options: ['Het gebruik van pesticiden in je tuin vermijden', 'Plant alleen gras in je tuin', 'Laat bijen nooit in de buurt van bloemen komen']
    },
    {
      question: 'Welke diersoort komt oorspronkelijk niet voor in Nederland (invasiesoort)?',
      answer: 'C',
      options: ['Bruinvis', 'Boomscope', 'Tijgermug']
    },
    {
      question: 'Wat is de belangrijkste techniek bij sprinten?',
      answer: 'C',
      options: ['Zo ver mogelijk achterover leunen tijdens het rennen', 'De knieën bij elke stap zo hoog mogelijk optillen', 'Korte, krachtige stappen maken']
    }
  ];

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 1) {
          return prevTime - 1;
        } else {
          stopTimer();
          handleTimeout();
          return 0;
        }
      });
      setProgress((prevProgress) => prevProgress - 5);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  useEffect(() => {
    const progressElement = document.querySelector('.progress');
    if (progressElement && timeLeft <= 5) {
      progressElement.classList.add('warning');
    } else if (progressElement) {
      progressElement.classList.remove('warning');
    }
  }, [timeLeft]);


  const resetTimer = () => {
    stopTimer();
    setTimeLeft(20);
    setProgress(100);
    startTimer();
  };

  const handleTimeout = () => {
    setFeedback(true);
    setShake(true);
    handleBeeMovement();
    setTimeout(() => {
      setShake(false);
      moveToNextQuestion();
    }, 1000);
  };

  useEffect(() => {
    if (gameStarted && !isGameOver) {
      startTimer();
    }

    return () => stopTimer();
  }, [gameStarted, isGameOver]);

  // Handle saving progress if the user tries to leave the page
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
      position: 'bottom-center',
      duration: 5000,
      style: {
        background: '#facc15',
        color: '#000',
      },
    });
  
    const saveProgress = async () => {
      try {
        if (existingGameId) {
          await updateGameData(existingGameId, correctCount);
        } else {
          const response = await storeGameData(userId, gameId, shopId, correctCount);
          setExistingGameId(response.result.id);
        }
      } catch (error) {
        console.error('Error saving progress on exit:', error.message);
      }
    };
    saveProgress();
  };
  
  useEffect(() => {
    if (gameStarted && !gameFinished) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [gameStarted, gameFinished, existingGameId, userId, gameId, shopId, correctCount]);
  
  useEffect(() => {
    if (gameFinished) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [gameFinished]);

  useEffect(() => {
    if (gameFinished) {
      const saveProgress = async () => {
        try {
          if (existingGameId) {
            // Update the existing entry
            await updateGameData(existingGameId, correctCount);
          } else {
            // Create a new entry
            const response = await storeGameData(userId, gameId, shopId, correctCount);
            setExistingGameId(response.result.id);
          }
          if (fetchScore) {
            fetchScore(true);
          }
        } catch (error) {
          console.error('Error saving game progress:', error.message);
        }
      };
      saveProgress();
    }
  }, [gameFinished, correctCount, userId]);


  const handleBeeMovement = () => {
    setIncorrectCount((prevCount) => {
      const newCount = prevCount + 1;

      if (newCount >= 5) {
        setBeePosition(0);
        setTimeout(() => {
          setIsGameOver(true);
          setGameFinished(true);
        }, 1000);
        return newCount;
      }

      setBeePosition((prevPosition) => Math.max(prevPosition - 16, 0));
      setTimeout(() => {
        document.querySelector('.progress-bar').classList.remove('expired');
        setIsClickable(true);
      }, 1000);

      return newCount;
    });
  };

  const handleAnswerSubmit = (selectedOption) => {
    if (!isClickable) return;

    setIsClickable(false);
    setSelectedOption(selectedOption);
    const correctAnswer = questions[currentQuestionIndex].answer;

    setCorrectOption(correctAnswer);

    if (selectedOption === correctAnswer) {
      setFeedback(true);
      new Audio(pingSound).play();
      setCorrectCount(correctCount + 1);
      stopTimer();
    } else {
      stopTimer();
      new Audio(buzzSound).play();
      setShake(true);
      handleBeeMovement();
      setFeedback(true);
    }

    setTimeout(() => {
      setShake(false);
      moveToNextQuestion();
      setIsClickable(true);
      setCorrectOption(null);
    }, 1000);
  };

  const moveToNextQuestion = () => {
    if (isGameOver || incorrectCount >= 10) {
      return;
    }
    setFeedback(false);
    setSelectedOption(null);
    setCorrectOption(null);
    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      resetTimer();
    } else {
      setIsGameOver(true);
      setTimeout(() => {
        setGameFinished(true);
      }, 1500);
    }
  };
  const handleFinishGame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStartGame(false);
      history.push('/games?game=5');
    }, 5000);
  };

  const beginGame = () => {
    setGameStarted(true);
  };

  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} />
      </>
    );
  }

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }

  if (gameFinished) {
    return (
      <>
        <GameResult
          score={correctCount}
          isBeeGame={true}
          onNextGame={handleFinishGame}
        />
      </>
    );
  }

  return (
    <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`}>
      <div className='highlight-current-game'> 4 </div>
      {startGame && (
        <div className="top-right-wooden-sign-container">
          <p>Beantwoord zoveel mogelijk vragen goed!</p>
        </div>
      )}
      {isGameOver ? (
        <div className="game-over">
        </div>
      ) : !startGame ? (
        <VideoPlaceholder />
      ) : (
        <div className="game-content">
          <div className="left-section-bees-game">
            <h2>De Achtervolging</h2>
            <p>
              Beantwoord zoveel mogelijk vragen goed om de zwerm bijen voor te
              blijven en je route te vervolgen. Heb je teveel vragen fout? Dan
              moet je helaas je bijensteken behandelen. Dit kost tijd en gaat van
              je puntentotaal af.
            </p>
            <div className="bee-progress">
              <img
                src="/media/images/bees-picture2.png"
                alt="Progress bar"
                className="progress-bar-image"
              />
              <img
                src="/media/images/increasingbees2.png"
                alt="Bees"
                className={`bees-image ${shake ? 'shake' : ''}`}
                style={{ top: `${100 - beePosition}%` }}
              />
            </div>
          </div>

          <div className="right-section-bees-game">
            <div className="question-box-bees-game">
              <p>{questions[currentQuestionIndex].question}</p>
              <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
              </div>
            </div>
            <div className="options">
              {['A', 'B', 'C'].map((option, index) => (
                <button
                  key={option}
                  className={`option 
                    ${feedback && selectedOption === option && option === questions[currentQuestionIndex].answer
                      ? 'correct'
                      : feedback && selectedOption === option && option !== questions[currentQuestionIndex].answer
                        ? 'incorrect'
                        : feedback && correctOption === option 
                          ? 'correct-highlight' 
                          : ''
                    }`}
                  data-label={option}
                  onClick={() => handleAnswerSubmit(option)}
                  disabled={feedback || isGameOver || gameFinished || !isClickable}
                >
                  <div className="label-circle">{option}</div>
                  {questions[currentQuestionIndex].options[index]}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BeeGame;
