import { Card } from "primereact/card";
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import Footer from '../../../components/webshop/footer/Footer';

export default function ResetPassword(props) {
  const { rootComponent } = props
  const messages = useRef(null);
  const API = process.env.REACT_APP_MTG_BACKEND + '/api'

  useEffect(() => {
    axios.post(API + '/webshop/reset-password', { token: rootComponent.props.match.params.token }).then(res => {
      messages.current.show([
        { sticky: true, severity: 'success', detail: 'Je hebt een mail ontvangen met je nieuwe wachtwoord!', life: 30000, },
      ]);
    }).catch(err => {
      messages.current.show([
        { sticky: true, severity: 'error', detail: 'Er is iets mis gegaan', life: 30000, },
      ]);
    })
  }, [API, rootComponent.props.match.params.token]
  )

  return (
    <React.Fragment>
      <div className="p-grid page-wrapper page-newpw">
        <div className='p-xl-6 p-md-6 p-col-12 col-left'>
          <div className="login">
            <div className="login-card-wrapper">
              <Card>
                <div className="p-grid">
                  <div className="p-col-12">
                    <img className="logo" src="/media/images/nw_logo.png" alt="" />
                    <Messages ref={messages} />
                  </div>
                </div>
              </Card>
              <Card>
                <div className="p-grid">
                  <div className="p-col-12">
                    <Link
                      className="p-button p-component btn-login"
                      style={{ marginTop: '2rem' }}
                      href='/'><span className="p-button-label p-c">Naar inloggen</span></Link>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className='p-xl-6 p-sm-6 p-col-12 col-right'>
          <div className="login-content">
            <div className='hero-wrapper'>
              <img className={`bg-image active`} src={"/media/images/login_image_1.jpg"} alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}



