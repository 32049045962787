import React, { useContext, useRef, useState } from 'react'
import { TcmsContext } from "../../../contexts/TcmsContext";
import { Card } from "primereact/card";
import Tcms from "../../../tcms/Tcms";
import axios from "axios";
import { OverlayPanel } from "primereact/overlaypanel";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import TcmsSpan from '../../../tcms/Tags/TcmsSpan';

export default function Cart(props) {
  const op = useRef()
  const rezipProducts = [107734, 107735]
  const rezipAllowedProducts = {
    38850: 107734,
    98834: 107734,
    98836: 107734,
    100365: 107735,
    99579: 107735
  }
  let context = useContext(TcmsContext)

  // This is so we can work with only 1 overlay
  let [overlayContent] = useState()

  const canAddRezipProduct = (item) => {

    if (context.meta.wso_id !== 953) {
      return false;
    }

    if (rezipAllowedProducts[item.id]) {
      let rezipProduct = rezipAllowedProducts[item.id];
      if (context.cart.items.filter(e => e.id === rezipProduct).length > 0) {
        return false;
      }
      return true;
    }
    return false;
  }

  const isItemRezipProduct = (id) => {
    if (rezipProducts.includes(id)) {
      return true;
    }
    return false;
  }

  /**
   * Compose red text after product display_name when not (enough) available
   * !! This routine is 'tweakable' !!
   */
  const statusHeader = (item) => {

    // When admin and status not empty !! tweak !!
    let { status, soldout, qty_left } = item.avail

    if (isItemRezipProduct(item.id)) {
      status = 'ok';
    }

    if (status === 'ok') return null

    switch (status) {
      case 'ok':
        return null
      case 'not_available':
        return <span><Tcms>Dit product is niet meer beschikbaar</Tcms></span>
      case 'not_enough_stock':
        if (soldout) {
          return <span><Tcms>Nog maar</Tcms> {qty_left} <Tcms>beschikbaar</Tcms></span>
        } else {
          return <span><Tcms>Op dit moment maar</Tcms> {qty_left} <Tcms>beschikbaar.</Tcms></span>
        }
      case 'out_of_stock':
        if (soldout) {
          return <span><Tcms>Helaas is dit product uitverkocht</Tcms></span>
        } else {
          return <span><Tcms>Op dit moment is dit product niet op voorraad</Tcms></span>
        }

      default:
    }
  }

  const deleteClicked = (id) => {
    axios.get(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/remove-from-cart', {
      params: {
        id
      }
    }).then(res => {
      // Have to update the jwt_token
      context.setCart(res.data.cart)
      context.renewJwt(res.data.jwt_token)
    })
  }

  const addRezipProduct = (product_id, id) => {

    axios.get(process.env.REACT_APP_MTG_BACKEND + '/api/webshop/add-free-product-to-cart', {
      params: { id: id, count: 1 }
    }).then(res => {
      // Have to update the jwt_token
      context.setCart(res.data.cart)
      context.renewJwt(res.data.jwt_token)
    })
  }

  let errSrc = '/media/images/placeholders/85x85.png'

  /**
   * * !! This routine is 'tweakable' !!
   */
  const itemTemplate = () => {

    return context.cart.items.map(item => {

      let src = `${process.env.REACT_APP_MTG_BACKEND}/storage/img/odoo-producten/85x85/${item.image_name}.jpg`

      return (
        <Card key={item.id} className={item.avail.status !== 'ok' ? 'out-of-stock' : ''}>

          <div className="order-line">
            <div className="image" style={{ width: '85px' }}>
              {isItemRezipProduct(item.id) ?
                <img src={'https://backend.nationalewaarderingsbon.nl/storage/img/odoo-producten/85x85/0_107735.jpg?1734421571113'} alt={item.display_name} />
                :
                <img data-src={src} src={src} alt={item.display_name} onError={e => e.target.src = errSrc} />
              }
            </div>
            <div className="name_description" style={{ width: '100%' }}>
              <h4>
                {item.display_name}
              </h4>
              <span className="cart__alert">{statusHeader(item)}</span>
              <div className="cart__content-bottom">
                <p className="count"><Tcms>Aantal:</Tcms> {item.count}</p>
                {context.meta.no_price === 0 && item.ecos > 0 &&
                  <p className="amount">| &nbsp; <span className="eco"><img alt="" src="/media/icons/coins.svg" /></span> {item.count * item.ecos}
                    &nbsp;<Tcms>{item.count * item.ecos > 1 ? 'punten' : 'punt'}</Tcms>
                  </p>
                }
              </div>
              {canAddRezipProduct(item) &&
                <div className='rezip'>
                  <a className='p-component p-button' onClick={() => addRezipProduct(item.id, rezipAllowedProducts[item.id])}>Rezip verpakking</a>
                </div>
              }
            </div>

            <div className="delete cart__delete" style={{ width: '85px' }}>
              {(item.ecos !== 0 || isItemRezipProduct(item.id)) && <TcmsButton
                title="Verwijder uit winkelmand."
                onClick={() => deleteClicked(item.id)}
                icon="pi pi-times"
                className="p-button-danger p-button-rounded p-button-outlined"
              />}

            </div>
          </div>
        </Card>

      )
    });
  }

  // The main return
  return (
    <React.Fragment>
      <OverlayPanel className="out-of-stock-overlay"
        ref={op}
        dismissable={context.isAdmin ? false : true}
        appendTo={document.getElementById('root')}>
        {overlayContent}
      </OverlayPanel>
      <div className="cart">
        {context.cart.items.length === 0 &&
          <TcmsSpan>no_products_in_cart</TcmsSpan>
        }
        {itemTemplate()}
      </div>
    </React.Fragment>
  )

}

