import { Card } from 'primereact/card';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TcmsContext } from '../../contexts/TcmsContext';
import TcmsH1 from '../../tcms/Tags/TcmsH1';
import TcmsP from '../../tcms/Tags/TcmsP';
import TcmsWYSIWYG from '../../tcms/Tags/TcmsWYSIWYG';
import Tcms from '../../tcms/Tcms';
import TcmsImg from '../../tcms/Tags/TcmsImg';
import TcmsButton from '../../tcms/Tags/TcmsButton';

function ThankYou(props) {
  const [userInfo, setUserInfo] = useState([]);
  const context = useContext(TcmsContext);
  const history = useHistory();
  const BE = process.env.REACT_APP_MTG_BACKEND;

  useEffect(() => {
    axios.get(BE + "/api/webshop/voucher").then((usr) => {
      setUserInfo(usr.data);
    });
  }, [BE]);

  useEffect(() => {
    const processDpgApi = async () => {
      try {
        const orderData = JSON.parse(sessionStorage.getItem("orderData"));
        if (!orderData) {
          return;
        }
        setUserInfo(orderData);

        sessionStorage.removeItem("orderData");

        const isMagazineOrder = await checkIfMagazineInCart(orderData);
        if (!isMagazineOrder) {
          return;
        }

        const hash = await getAuthHashDpgApi();
        if (!hash) {
          return;
        }

        const aanbiedingen = await fetchAllAanbiedingen(hash);
        if (!aanbiedingen) {
          return;
        }

        const magazineName = sessionStorage.getItem("magazineName");
        const issueCount = parseInt(sessionStorage.getItem("issueCount"), 10);

        if (!magazineName || !issueCount) {
          return;
        }

        const selectedAanbieding = aanbiedingen.find(
          (a) =>
            a.data.Titel &&
            magazineName.toLowerCase().includes(a.data.Titel.toLowerCase()) &&
            a.data.Aantal_nummers === issueCount
        );

        if (!selectedAanbieding) {
          return;
        }

        await callDpgApi(hash, selectedAanbieding.key, orderData);
      } catch {
      }
    };

    processDpgApi();
  }, []);

  const getAuthHashDpgApi = async () => {
    const originalAuthToken = axios.defaults.headers.common["MtgJwt"];

    try {
      delete axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["MtgJwt"];

      const response = await axios.post(
        "https://www.mijnbladopdemat.nl/engine/api/v3/login",
        {
          loginname: "morethangifts@bladcadeau.nl",
          password: "N7hk8umztWAG",
        }
      );

      return response.data?.authhash || null;
    } catch {
      return null;
    } finally {
      if (originalAuthToken) {
        axios.defaults.headers.common["MtgJwt"] = originalAuthToken;
      }
      const jwt_token = sessionStorage.getItem("jwt_token");
      if (jwt_token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${jwt_token}`;
      }
    }
  };

  const checkIfMagazineInCart = async (orderData) => {
    const cartItems = context.cart?.items || [];
    for (const item of cartItems) {
      try {
        delete axios.defaults.headers.common["Authorization"];

        const response = await axios.get(
          `https://backend.nationalewaarderingsbon.nl/api/webshop/product?id=${item.id}`,
          {
            headers: {
              Authorization: `Bearer ${axios.defaults.headers.common["MtgJwt"]}`,
            },
          }
        );

        if (
          response.data?.display_name &&
          response.data.display_name.toLowerCase().includes("nummers")
        ) {
          return true;
        }
      } catch {
      } finally {
        const jwt_token = sessionStorage.getItem("jwt_token");
        if (jwt_token) {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${jwt_token}`;
        }
      }
    }
    return false;
  };

  const fetchAllAanbiedingen = async (hash) => {
    const originalAuthToken = axios.defaults.headers.common["MtgJwt"];

    try {
      delete axios.defaults.headers.common["MtgJwt"];

      const response = await axios.get(
        'https://www.mijnbladopdemat.nl/engine/api/v3/query?querydef={"class":"aanbieding"}',
        {
          headers: {
            Authorization: `Bearer ${hash}`,
          },
        }
      );
      return response.data?.recordList || [];
    } catch {
      return null;
    } finally {
      if (originalAuthToken) {
        axios.defaults.headers.common["MtgJwt"] = originalAuthToken;
      }
    }
  };

  const callDpgApi = async (hash, aanbiedingKey, orderData) => {
    const originalAuthToken = axios.defaults.headers.common["MtgJwt"];

    try {
      delete axios.defaults.headers.common["MtgJwt"];

      await axios.post(
        "https://www.mijnbladopdemat.nl/engine/api/v3/record/aanmelding",
        {
          Aanhef: "M",
          Voorletter_s_: orderData.firstname.charAt(0),
          Voornaam: orderData.firstname,
          Achternaam: orderData.lastname,
          Postcode: orderData.zip,
          Huisnummer: orderData.huisnummer,
          Huisnummer_toevoeging: orderData.toevoeging || null,
          Straat: orderData.straatnaam,
          Plaats: orderData.city,
          Emailadres: orderData.private_email,
          Aanbieding: aanbiedingKey,
          Land: "nl",
        },
        {
          headers: {
            Authorization: `Bearer ${hash}`,
          },
        }
      );
    } finally {
      if (originalAuthToken) {
        axios.defaults.headers.common["MtgJwt"] = originalAuthToken;
      }
    }
  };

  return (
    <>
      <Card className="p-mt-6">
        <div className="banner-content">
          <div className="banner">
            <TcmsImg
              src="/media/images/bedankt.png"
              alt=""
              className="p-bg-contain"
            ></TcmsImg>
          </div>
        </div>
        <div className="p-px-6">
          <div>
            <TcmsH1 className="p-text-center">ty_title</TcmsH1>
            <p>
              <Tcms>ty_beste</Tcms> {userInfo.firstname},
            </p>
            <TcmsP>ty_thankyou</TcmsP>
            <p>
              <Tcms>ty_email</Tcms> {userInfo.private_email}.
              <br />
              <Tcms>ty_noemail</Tcms>
            </p>

            <TcmsWYSIWYG>ty_text</TcmsWYSIWYG>
          </div>
          <div className="p-flex p-justify-content-between">
            {/* <Button label='Helpdesk' className='p-flex p-mr-2'/> */}
            <TcmsButton
              onClick={() =>
                history.push(`/${context.webshopSlug}/bestellingen`)
              }
              label="Mijn bestellingen"
              className="p-flex"
            />
          </div>
        </div>
      </Card>
    </>
  );
}

export default ThankYou;
