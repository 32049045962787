import React, {useEffect, useState} from "react";
import Tcms from "../../../tcms/Tcms";
import axios from "axios";
import TcmsH1 from "../../../tcms/Tags/TcmsH1";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import {RadioButton} from "primereact/radiobutton";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import {Card} from "primereact/card";
import {getUser, User} from "../../components/User";
import TcmsH2 from "../../../tcms/Tags/TcmsH2";

const Poll = () => {
    const BE = process.env.REACT_APP_MTG_BACKEND
    const [user, setUser] = useState()
    const [edit, setEdit] = useState()
    const [yourVote, setYourVote] = useState('')
    const [poll, setPoll] = useState([])
    const [result, setResult] = useState([])
    const [activeOption, setActiveOption] = useState({id: 0})

    useEffect(() => {
        axios.get(BE + '/api/webshop/widgets/poll').then(res => {

            switch (res.data.type) {
                case 'poll':
                    setPoll(res.data.questions)
                    break;
                case 'result':
                    setResult(res.data.result)
                    setYourVote(res.data.question)
                    break;
                default:
                //
            }

            getUser().then(res => {
                setUser(res.data)
            });

        })
    }, [BE])

    const vote = () => {
        axios.post(BE + '/api/webshop/widgets/poll', {poll: activeOption}).then(res => {
            setPoll([])
            setResult(res.data.result)
            setYourVote(res.data.question)

        })
    }

    const voteHtml = () => {
        return <>
            <TcmsH1 className="title_poll">Breng jouw stem uit</TcmsH1>
            <TcmsWYSIWYG>_widget_poll_</TcmsWYSIWYG>
            <TcmsH2>_poll_vraag_</TcmsH2>
            <Card className="widget-berichten">
                {poll.map((element) =>
                    <div className="p-field-radiobutton">
                        <RadioButton key={element.id}
                                     inputId={'option_' + element.question}
                                     name="option"
                                     value={element.id}
                                     checked={activeOption.id === element.id}
                                     onChange={(e) => setActiveOption(poll.find(s => s.id === e.value))}
                        />
                        <label htmlFor={'option_' + element.question}
                               dangerouslySetInnerHTML={{__html: `${element.question}`}}/>

                    </div>)}
            </Card>
            <TcmsButton disabled={activeOption.id === 0}
                        label="_stemmen_op_muziek_bevestig_"
                        onClick={() => vote()}

            />
        </>
    }

    const resultHtml = () => {
        return <>
            <TcmsH1 className="title_song">Resultaat</TcmsH1>

            <div className="p-grid">
                <div className="p-col-12 text-center">
                    <h3 className="result"><Tcms>Je hebt gestemd op</Tcms> <span dangerouslySetInnerHTML={{__html: yourVote}}/></h3>
                </div>
                <div className="p-col-12">
                    <h3 className="result_low">Hier onder het resultaat:</h3>
                </div>
            </div>
            {result.map(el => (
                <div className="p-grid box">
                    <div className="p-col-2" style={{textAlign:'center'}}>
                        {el.total}
                    </div>
                    <div className="p-col-10">
                        <span dangerouslySetInnerHTML={{__html: el.question}}></span>
                    </div>
                </div>
            ))}
        </>
    }

    if (!user) return null

    const completeUser = !(user.firstname && user.lastname && user.private_email && !edit)

    return <>
        {completeUser
            // When not enough user data available ask user to complete
            ? <User userData={user} onUserChanged={usr => {
                setUser(usr)
                setEdit(false)
            }}/>
            // Below the voting or result part
            : (result.length !== 0 ? resultHtml() : voteHtml())}
    </>
}

export default Poll
