import {Dialog} from "primereact/dialog";
import Tcms from "../../../tcms/Tcms";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import {useHistory} from "react-router-dom";
import {TcmsContext} from "../../../contexts/TcmsContext";

const DialogLogout = forwardRef((props, ref) => {
  const context = useContext(TcmsContext)
  const history = useHistory()
  const [visible, setVisible] = useState(false)

  useImperativeHandle(ref, () => ({
    open() {
      setVisible(true)
    }
  }));

  return <Dialog
    header={
      <div>
        <h2><Tcms>Weet je zeker dat je wil uitloggen?</Tcms></h2>
      </div>
    }
    className={` dialog-small wso-`+context.meta.wso_id}
    visible={visible}
    style={{width: '40em'}}
    modal={true}
    onHide={() => setVisible(false)}>
    <div className="p-grid">
      <div className="p-col-6">
        <TcmsButton
          className="p-button-outlined p-button-secondary"
          onClick={() => setVisible(false)}
          label="Nee ik wil blijven shoppen"/>
      </div>
      <div className="p-col-6">
        <TcmsButton
          style={{float: 'right'}}
          className="p-button-info"
          onClick={() => {
            context.logout()
            history.push('/' + context.webshopSlug)
          }}
          label="Ja ik wil uitloggen"/>
      </div>
    </div>
  </Dialog>
})

export default DialogLogout
